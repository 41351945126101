import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import { useApolloClient, useMutation } from '@apollo/client'
import { updateCacheField } from '@lynit/shared/src/utils/apollo'
import whitePlusIconYellowBackground from '@lynit/shared/src/images/whitePlusIconYellowBackground.svg'
import { createNodeId, getNodeIcon, structureTagMatcher, vizTitleLengthFormat } from "@lynit/shared/src/utils/utils";
import supportingIcon from '@lynit/shared/src/images/supporting-structure-tag.svg'
import opposingIcon from '@lynit/shared/src/images/opposing-structure-tag.svg'
import beatIconDark from '@lynit/shared/src/images/beatDark.svg'
import trashIcon from '@lynit/shared/src/images/IconDelete.svg'
import addBeatIcon from '../../images/addBeatIcon.svg'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import {
  AddBeat,
  ArrowIconConnectionCard,
  BeatDrivenCount,
	BeatsContainer,
	ConnectionDescription,
	ConnectionIconContainer,
  CountIcon,
  CreateBeat,
  CreateBeatQuill,
  DeleteIcon,
  DriverSection,
  RightDescription,
} from './styles'
import { useCreateBeatMutation, useCreateConnectionMutation, useUpdateRelationshipMutation, useCreateSharedBeatMutation } from "@lynit/shared/src/hooks";
import {
	SortableContext,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider';
import TimelineItem from '@mui/lab/TimelineItem';
import DraggableBeats from './DraggableBeats';
import { makeStyles } from '@mui/styles'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles';
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import DescriptionField from '@lynit/shared/src/ui/DescriptionField';
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider';
import { systemStateContext } from '@lynit/shared/src/state/systemProvider';
import SuggestionTooltip from '@lynit/shared/src/ui/ToolTip/SuggestionTooltip';
import tooltipIcon from '@lynit/shared/src/images/tool-tip.svg'
import arrow from '@lynit/shared/src/images/downCheveron.svg'
import { sharedContext } from '@lynit/shared/src/state';
import { setNewConnId } from '@lynit/shared/src/state/actions';


// import arrow from '@lynit/shared/src/images/downCheveron.svg'


const useStyles = makeStyles(() => ({
  tooltip: {
    margin: 0,
    background: '#55534F',
    display: props => props.isTooltip && 'none',
  },
  tooltip1: {
    display: props => !props.isCollapsed && 'none',
    background: '#55534F',
  },
  initialsTooltip: {
    background: '#55534F',
  },
  arrow: {
    color: '#55534F',
  },
  timeline: {
    margin: '0',
    padding: '7px 5px',
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
    minHeight: 'unset',
    boxSizing: 'border-box',
  },
  timelineContent: {
    padding: '0px 3px',
    width: 'calc(100% - 20px)',
  },
  timelineDot: {
    margin: 0,
    backgroundColor: '#78C091',
  },
  timelineChapter: {
    margin: 0,
    backgroundColor: nodeColors.Chapter,
    width: '8px',
    height: '8px',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    color: '#000000',
  },
  hiddenTimelineConnector: {
    visibility: 'hidden',
  },
  timelineConnector: {
    background: '#78C091',
  },
  dashedTimelineConnector: {
    background: 'transparent',
    border: '1px dashed #78C091',
    width: 0,
  },
  tab: {
    color: '#888585',
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'none',
    minWidth: '70px',
    padding: '12px 5px',
  },
  tabSelected: {
    color: '#000000 !important',
  },
  indicator: {
    height: '6px',
    borderRadius: '10px',
    backgroundColor: '#FFC300',
  },
  tabs: {
    borderBottom: '6px solid #fff5d4',
    borderRadius: '4px',
  },
}))


const NewConnectionCard =  (
  {
    id, 
    name, 
    text, 
    type, 
    structureTag, 
    handleClick, 
    beatsDriven, 
    sharedBeats,
    setIsSetModifiers,
    setCurrentElement,
    referenceElement,
    destNode,
    description,
    firstBeat,
    tabValue,
    setOpenedConnectionCard,
		openedConnectionCard,
  }) => {
  
  const client = useApolloClient()
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation()
  //const [isCollapsed, setIsCollapsed] = useState(!!parseInt(beatsDriven))
 
  const [beatsList, setBeatsList] = useState([])
  const graphData = useContext(graphDataStateContext)
  const [newBeat, setNewBeat] = useState(null)
  //const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
  const [performCreateSharedBeat] = useCreateSharedBeatMutation({ ignoreResults: true })
  const [beatContentChanged, setBeatContentChanged] = useState(!!firstBeat)
  const [createConnection] = useCreateConnectionMutation({ ignoreResults: true })
  const beatsRelatedData = useContext(beatsDataStateContext)
  const { createLog, toastHandler } = useContext(systemStateContext)
  const [isEdit, setEdit] = useState(false)
	//const [connectionDescription, setConnectionDescription] = useState(description)
  const [collaspedCardHovered, setCollapsedCardHovered] =  useState(false)
    
  const {
		state: {
			
			cacheConnData: { newConnId },
		},
		dispatch: sharedDispatch,
	  } = useContext(sharedContext)



  const [isCollapsed, setIsCollapsed] = useState(newConnId !== id || openedConnectionCard !==id )

  useEffect(() => {
   
   if(newConnId === id){
    sharedDispatch(setNewConnId(false))
    setOpenedConnectionCard(id)
    createLog(
      'Connection Details Expanded',
      `{"method":"Create New Connection"}`,
      'Connection Card',
      null,
    ) 
   } else if (isCollapsed && (openedConnectionCard === id)){

    createLog(
      'Connection Details Expanded',
      `{"method":"Only One Connection"}`,
      'Connection Card',
      null,
    )
  }

    
	}, [])

  
  useEffect(() => {
    if(openedConnectionCard ==id && graphData?.nodes[referenceElement.id]?.staticConnections?.length >1 ){
  
      const connectionCard = document.getElementById(`${id}-connectionCard`)
      
      setTimeout(()=> connectionCard?.scrollIntoView({ behavior: 'smooth', block: 'start' }),200)
    } 
     
    
    }, [openedConnectionCard])

  
  useEffect(() => {
    if(!isCollapsed && newConnId !== id){
      setIsCollapsed(true)
    } else if(openedConnectionCard === id){
      setIsCollapsed(false)
    }
   
    
	}, [openedConnectionCard])

  const defaultConnectionDescriptions = {
		Ally: 'Describe how these two characters help each other overcome their weaknesses.',
		Opponent: 'Describe how these two characters exploit each other’s weaknesses.',
		Protagonist: 'Describe how this Protagonist uniquely pursues this Arc?',
		Antagonist: 'Describe how this Antagonist uniquely complicates this Arc?',
		Supporting: 'Describe how these elements help each other overcome their weaknesses?',
		Opposing: 'Describe how these elements attack each other’s weaknesses?',
	}
  

  const classes = useStyles({
    isTooltip: false,
    isCollapsed: false,
  })
  
  const shortname = vizTitleLengthFormat(name)

  const handleStructureTagChange = (id, structureTag) => {
		let currentStructureTag
		if (structureTag === 'Ally' || structureTag === 'Opponent') {
			currentStructureTag = structureTag === 'Ally' ? 'Opponent' : 'Ally'
		} else if (structureTag === 'Protagonist' || structureTag === 'Antagonist') {
			currentStructureTag = structureTag === 'Protagonist' ? 'Antagonist' : 'Protagonist'
		} else if (structureTag === 'Opposing' || structureTag === 'Supporting') {
			currentStructureTag = structureTag === 'Opposing' ? 'Supporting' : 'Opposing'
		} else if (!structureTag){

      currentStructureTag = structureTagMatcher(referenceElement.__typename, destNode.__typename, true)
    }

    else  {
			currentStructureTag = structureTag
		}
    
    // updateCacheField(client,{ id, __typename: 'Relationship' },{structureTag:currentStructureTag},true)

		//updateCacheStructureTagElement(client, { id, __typename: 'Relationship' }, currentStructureTag, true)
    performUpdateRelationshipMutation({
      id,
      input: {
        structureTag: currentStructureTag,
      },
      client,
      __typename: 'Relationship',
      newMapValue:{structureTag:currentStructureTag},
      broadcast:true
  })
		
	}
  
  const createBeatHandler = async (beatContent = JSON.stringify({ ops: [{ insert: '\n' }] })) => {
    const beatId = createNodeId('Beat')
    const relId = createNodeId('Relationship')
    const tempData = {
      createBeat: {
        beat: {
          id: beatId,
          beatConnections: [],
          driverConnections: [],
          description: beatContent,
          driverConnections: [],
          order: null,
          parentId: referenceElement.id,
          staticConnections: [],
          noteConnections: [],
          title: '',
          createdAt: '',
          updatedAt: '',
          xCoordinate: null,
          yCoordinate: null,
          vizLocSaved: false,
			    firstBeat: '',
          __typename: 'Beat',
        },
        relationship: {
          arc: null,
          connectionType: 'Driver',
          destNode: {
            id: beatId,
            __typename: 'Beat',
          },
          id: relId,
          order: String(referenceElement.driverConnections.length || 0),
          sourceNode: {
            id:referenceElement.id,
            __typename: referenceElement.__typename,
          },
          name: null,
          description: null,
          arcStage: null,
          relName: null,
          __typename: 'Relationship',
          structureTag: null,
          beatsDriven: null,
          sharedBeats:[],
          firstBeat: '',
          createdAt: new Date().toISOString()
        },
      },
    }
    const destNodeDriverConnection = {
      arc: null,
      connectionType: 'Driver',
      destNode: {
        id: beatId,
        __typename: 'Beat',
      },
      id: createNodeId('Relationship'),
      order: String(destNode.driverConnections.length || 0),
      sourceNode: {
        id:destNode.id,
        __typename: destNode.__typename,
      },
      name: null,
      description: null,
      arcStage: null,
      relName: null,
      __typename: 'Relationship',
      structureTag: null,
      beatsDriven: null,
      sharedBeats:[],
      firstBeat: '',
      createdAt: new Date().toISOString()

    }
    const staticConnectionUpdateFields = {beatsDriven:String(+beatsDriven +1), sharedBeats:(sharedBeats || []).concat( [tempData.createBeat.beat])}
 
    setTimeout(() => {
      const elem = document.getElementById(`${id}-beatBreakdownContainer`)
      elem.scrollTo({
        top: elem.scrollHeight,
        behavior: 'smooth',
      })
    })

    createLog(
      `Beat Creation Attempted`,
      `{"workflowStep":${1},"parentNodeType":"${type}"}`,
      'Connection Card',
      'Beat Creation',
    )

    
    setNewBeat(tempData.createBeat.beat)

    performCreateSharedBeat({
      tempData,
      destNodeDriverConnection,
      staticConnectionUpdateFields,
      id,
      sharedBeats,
      beatsDriven,
      client
    })
    // createBeat({
    //   variables: {
    //     input: {
    //       id: beatId,
    //       description: beatContent,
    //       relId,
    //     },
    //     parentId: referenceElement.id,
    //   },
    //   ignoreResults: true,
    //   // async onCompleted(data) {
    //   // 	if (driverConnections.length) {
    //   // 		setNewBeat(data.createBeat.beat)
    //   // 	}
    //   // 	await createCacheElement(client, data, false)
    //   // 	createCacheConnection(client, data.createBeat.relationship)
    //   // 	await deleteCacheElement(client, tempData.createBeat.beat)
    //   // 	deleteCacheConnections(client, tempData.createBeat.relationship)
    //   // },
    // }).then( () => {

    //    //Need to create connection to destnode
    //   createConnection({
		// 		variables: {
		// 			id: destNodeDriverConnection.id,
		// 			destId: destNodeDriverConnection.destNode.id,
		// 			sourceId: destNodeDriverConnection.sourceNode.id,
		// 			description: "",
		// 			connectionType: 'Driver',
		// 			structureTag: null,
		// 			arcStage: null,
		// 			arc: null,
		// 			name: null,
		// 			relName: 'RELATED',
		// 			beatsDriven: null,
		// 			sharedBeats: null,
    //       firstBeat: ''
		// 		},
		// 	})

    //   updateRelation({
    //     variables: {
    //       id,
    //       input: {beatsDriven:staticConnectionUpdateFields.beatsDriven,sharedBeats:staticConnectionUpdateFields.sharedBeats.map(beat => { return {id:beat.id}})},
    //     },
    //   })
      


    // })
    quill?.setText('')
    setBeatContentChanged(false)
  }

  const checkBeatContentChanged = (updateId, content, text) => {

    if (text.length >0) {
      // updateCacheField(client,{id:id,__typename:"Relationship"},{firstBeat: content},false)
      performUpdateRelationshipMutation({
          id,
          input: {
            firstBeat: content,
          },
          client,
          __typename: 'Relationship',
          newMapValue:{firstBeat: content},
          broadcast:false
      })
       
      setBeatContentChanged(true)
    } else {
      setBeatContentChanged(false)
    }

  }
  const quillMap = {}
  
  const { quill, quillRef } = useQuillAutoSave(
    {
      modules: {
        toolbar: false,
        
      },
      placeholder: 'Plan this relationship with Beats',
    },
    checkBeatContentChanged,
    id + '-' + 'beat',
    "firstBeat",
    firstBeat,

  )

  useEffect(()=> {

    if(beatsList?.length > 0 && firstBeat){

      quill?.setText('')
      performUpdateRelationshipMutation({
        id,
        input: {
          firstBeat: '',
        },
        client,
    })
    }
  },[beatsList?.length === 0])



  useEffect(() => {
    const filteredConns  = sharedBeats?.map(beat => {
      
      return referenceElement?.driverConnections?.find(conn => [conn?.sourceNode?.id,conn?.destNode?.id].includes(beat?.id))

    })

    setBeatsList(filteredConns||[])
	}, [sharedBeats,referenceElement])
  

  return (
    <ConnectionContainer id={`${id}-connectionCard`}>

      <Div 
      beatsDriven ={beatsDriven}
     
      
     >
          
          <ToolTip title={structureTag || ''} placement="top" arrow>
            <ConnectionIconContainer>
              <img
                src={
                  structureTag === 'Ally' ||
                  structureTag === 'Supporting' ||
                  structureTag === 'Protagonist'
                    ? supportingIcon
                    : structureTag === 'Opponent' ||
                      structureTag === 'Opposing' ||
                      structureTag === 'Antagonist'
                    ? opposingIcon
                    : supportingIcon
                }
                onClick={() => handleStructureTagChange(id, structureTag)}
              />
            </ConnectionIconContainer>
          </ToolTip>
          <CollapsedConnection
            isCollapsed={isCollapsed}
            onMouseEnter={()=> {
              if(isCollapsed){

                createLog(
                  `Collapsed Connection Hovered `,
                  `{"beatsDriven":"${beatsDriven}"}`,
                  'Connection Card',
                  null,
                )

              }
            
              setCollapsedCardHovered(true)}}
            onMouseLeave={()=> setCollapsedCardHovered(false)}
            onClick={e => {
              
             
            setOpenedConnectionCard(isCollapsed ? id : null)
            
            createLog(
              `Connection Details ${isCollapsed? "Expanded" : "Collapsed"}`,
              `{"method":"Clicked on Connection", "beatsDriven":"${beatsDriven}"}`,
              'Connection Card',
              null,
            )
             
          }}
          >
            <div className="icon-container">
              <img src={getNodeIcon(type)} />
            </div>
            
            
            <Div3>{`${shortname}`}</Div3>
            <ToolTip title={isCollapsed ? "Expand Beat Breakdown" : "Close Beat Breakdown" || ''} placement="top" arrow>
              <ArrowIconConnectionCard 
                src={arrow}
                alt="Arrow"
                isCollapsed={isCollapsed}
                collaspedCardHovered ={collaspedCardHovered}
              />
            </ToolTip>
           
              
            <CountIcon>
                <p>{beatsDriven || 0}</p>
            </CountIcon>
              <img
                  src={beatIconDark}
                  alt="Beat icon dark"
              />
            
              
          </CollapsedConnection>
          <ConnectionDescription>
            <DescriptionField
              id={id}
              fieldName={"description"}
              type={type}
              isEdit={isEdit}
              setEdit={setEdit}
              elementDescription={description ?? ''}
              connectionType="Static"
              createLog={createLog}
              placeholder={defaultConnectionDescriptions[structureTag]	} />
              <RightDescription>
                <DeleteIcon
                  disabled={beatsRelatedData?.serverError || beatsRelatedData?.noElementError}
                  src={trashIcon}
                  alt="Delete Button"
                  onClick={() => {
                    if (!JSON.stringify(id)?.includes('temp')) {
                      setCurrentElement({ id, type: 'Connection' })
                      createLog(
                        'Connection Deletion Attempted',
                        `{"workflowStep":${1},"connectionType":"Static"}`,
                        'StoryViz',
                        'Connection Deletion',
                      )
                    }
                  }}
                />
              </RightDescription>
          </ConnectionDescription>
         
      </Div>
      {(<BeatsContainer
          id={`${id}-beatBreakdownContainer`}
          isVisible={!isCollapsed && ['Character', 'Arc', 'Event', 'Theme'].includes(type)}
          isEmpty={!beatsList?.length}
          className='beatsContainer'
        >
         

          <div style={{ display: "flex", alignItems: "center" }}>
            <DriverSection style={{ display: "flex", paddingLeft:"5px"}} >
              Beat Breakdown   

              <SuggestionTooltip
                  title="These are the Beats that demonstrate this relationship. Go to the Beats Tab for all Beats."
                  placement="bottom"
                  // arrow
                  onOpen={() => createLog(
                    `Suggestion Tooltip hovered`,
                    `{"Location": "Beat Breakdown"}`,
                    `Beat Breakdown`,
                    'Connection Creation',
                  )}
                >
                  <img className="tool-tip" src={tooltipIcon} alt="Tool-tip"  style={{ paddingLeft: "10px" }}/>
                </SuggestionTooltip>
            </DriverSection>
          </div>
          
          
          <Timeline className={classes.timeline}>
            {/* Create first beat */}
            { (<TimelineItem
              className={classes.timelineItem}
              style={{ display: beatsList?.length > 0 ? 'none' : 'flex' }}
            >
              <TimelineSeparator>
                <TimelineConnector className={classes.hiddenTimelineConnector} />
                <TimelineDot className={classes.timelineDot} />
                <TimelineConnector className={classes.hiddenTimelineConnector} />
                {beatContentChanged ? <div style={{ height: '20px' }} /> : <></>}
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <CreateBeatQuill 
                  ref={quillRef} 
                  id={id+'-'+'beat'} 
                  data-fieldname={"firstBeat"} 
                  translate="no" 
                  onBlur={()=> updateCacheField(client,{id:id,__typename:"Relationship"},{firstBeat: JSON.stringify(quill.getContents())},true)}
                  />
                {beatContentChanged && (
                  <AddBeat
                    onClick={() =>
                      createBeatHandler(JSON.stringify(quill?.getContents()))
                    }
                  >
                    + Create First Shared Beat
                  </AddBeat>
                )}
              </TimelineContent>
            </TimelineItem>)}

            {/* Beats */}
            <SortableContext
           items={beatsList?.map(conn => {
            const beat = conn?.destNode?.__typename === 'Beat'
            ? graphData.nodes[conn?.destNode?.id] 
            : graphData.nodes[conn?.sourceNode?.id]
            
            return beat?.beatConnections?.[0] ? conn?.id  + "-Shadow-" + id : conn?.id
           })}
            //items={beatsList?.map(beat => beat?.id )}
            strategy={verticalListSortingStrategy}
          >
            {
              !isCollapsed && (tabValue === 0) && beatsList?.map((driverConnection, index) => {
                
                const beat = driverConnection?.destNode.__typename === 'Beat'
                      ? graphData.nodes[driverConnection?.destNode?.id] 
                      : graphData.nodes[driverConnection?.sourceNode?.id]

                const nodeId =
                  beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
                    ? beat?.beatConnections?.[0]?.sourceNode?.id
                    : beat?.beatConnections?.[0]?.destNode?.id
                const node = graphData?.nodes[nodeId]
                return (
                  <TimelineItem
                    key={`${driverConnection?.id}`}
                    className={classes.timelineItem}
                  >
                    <DraggableBeats
                      index={index}
                      node={node}
                      driverConnections={beatsList}
                      beatId={beat?.id}
                      setCurrentElement={setCurrentElement}
                      referenceElementType={type}
                      referenceElement={referenceElement}
                      connectionId={driverConnection?.id}
                      isNewBeat={beat?.id === newBeat?.id}
                      shouldFocus={
                        false
                      }
                      setNewBeat={setNewBeat}
                      beatConnection={beat?.beatConnections?.[0]}
                      beatsDriven={beatsDriven}
                      setIsSetModifiers={setIsSetModifiers}
                      sharedBeat={true}
                      locationId={id}
                      location={"Connection Card"}
                    />
                  </TimelineItem>
                )
              })}
            </SortableContext>
                              
              {beatsList?.length ? (
              <TimelineItem className={classes.timelineItem}>
                <TimelineSeparator>
                  <TimelineConnector className={classes.dashedTimelineConnector} />
                  <TimelineDot
                    className={classes.timelineDot}
                    style={{ padding: 0 }}
                  >
                    <img
                      src={addBeatIcon}
                      alt="Add Icon"
                      style={{ width: '8px' }}
                    />
                  </TimelineDot>
                  <TimelineConnector className={classes.hiddenTimelineConnector} />
                </TimelineSeparator>
                <TimelineContent className={classes.timelineContent}>
                  <CreateBeat
                    onClick={() => {
                      createBeatHandler()
                    }}
                  >
                    Type a new beat
                  </CreateBeat>
                </TimelineContent>
              </TimelineItem>
            ) : (
              <></>
            )}
          </Timeline>
      </BeatsContainer>)}
     
      

   </ConnectionContainer>
  );
}

export default React.memo(NewConnectionCard)

const Div = styled.div`
  margin-bottom:-7px;
  border-radius: 4px;
  border: 1px ${({beatsDriven})=> (beatsDriven > 0 ? 'solid' : 'dashed')} #000;
  
  position: relative;
  //display: flex;
  //width: 75%;
  //align-items: start;
  //justify-content: space-between;
  //gap: 20px;
  cursor: pointer;
  //padding: 3px 5px;
  //left: 40px;
  
  
  
 
`;

const ConnectionContainer = styled.div`
  border-radius: 4px;
  //border: 1px ${({beatsDriven})=> (beatsDriven > 0 ? 'solid' : 'dashed')} #000;
  //background-color: #fff;
  position: relative;
  //display: flex;
  width: 85%;
  //align-items: start;
  //justify-content: space-between;
  //gap: 20px;
  //cursor: pointer;
  padding: 3px 5px;
  left: 32px;
  top: -3px;
  
 
`;

const CollapsedConnection = styled.div`
  display: flex;
  gap: 7px;
  border-radius: 4px;
  background-color: #fff;
  height: 18px;
  ${({isCollapsed}) => (isCollapsed ? `:hover {
		background-color: #d7edde;
    border: 1px ${({beatsDriven})=> (beatsDriven > 0 ? 'solid' : 'dashed')} #000;
		//border-color: transparent;
	}` : ``)}
  padding: 3px 5px;
  img{
    object-fit: contain;
    height: 15px;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const Div3 = styled.div`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 600 12px Source Sans Pro, sans-serif;
  
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 14px;
  overflow: hidden;
  margin-top: 15px;
  max-width: 100%;
`;

