import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Popover } from '@mui/material'
import { useApolloClient } from '@apollo/client'

import { CREATE_CONNECTION } from '../../data'
import { createNodeId, nodeTypeForId } from '../../utils/utils'
import { sharedContext } from '../../state'
import { setNewConnId } from '../../state/actions'
import { createCacheConnection, deleteCacheConnections } from '../../utils/apollo'
import { setConnectionCount } from '@lynit/shared/src/state/actions'
import MenuItem from './MenuItem'
import { MenuContainer } from './styles'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { userStateContext } from '../../state/userProvider'
import { useCreateConnectionMutation } from '../../hooks'
import { systemStateContext } from '../../state/systemProvider'
import { useCreateConnectionAndElementMutation } from '../../hooks/createConnectionAndElement'

const filterItemsByType = (arr, type) => {
	return arr
		.filter(item => item.__typename === type)
		.map(item => ({
			title: item.name,
			items: [],
			type,
			node: item,
		}))
}

const ConnectionModal = ({ mainElementId, mainElementType, isOpen, handleClose, connectionModalType }) => {
	const client = useApolloClient()

	const graphData = useContext(graphDataStateContext)
	const {toastHandler} = useContext(systemStateContext)
	
	const isSupporting = connectionModalType.type === "Supporting"

	const [objectArray, setObjectArray] = useState(graphData?.allNodes?.nodes || [])

	const [performCreateConnectionMutation] = useCreateConnectionMutation()

	const [performCreateConnectionAndElement] = useCreateConnectionAndElementMutation()

	const {
		dispatch,
		state: { refetchUser }  ,
	} = useContext(sharedContext)
	
	const user = useContext(userStateContext)

	const recommendedDriver = graphData.nodes[connectionModalType.recommendedDriverId]

	// remove connected nodes
	useEffect(() => {
		const nodes = new Set()
		const staticConnections = graphData?.nodes?.[mainElementId]?.staticConnections || []

		nodes.add(mainElementId)
		staticConnections.forEach(connection => {
			nodes.add(connection.sourceNode.id)
			nodes.add(connection.destNode.id)
		})
    
		const nodesData = Object.values(graphData?.nodes)
			?.sort((a, b) => a?.name?.localeCompare(b?.name))
			?.filter(node => !nodes.has(node.id))

		setObjectArray(nodesData)
	}, [graphData?.nodes?.[mainElementId]?.staticConnections?.length,graphData?.allNodes?.nodes?.length])

	
	const supportingOptions = useMemo(
		() => [
			{
				title:
					mainElementType === 'Character'
						? 'Ally'
						: mainElementType === 'Arc'
						? 'Protagonist'
						: 'Character',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Character'),
				tooltip:
					'An Ally captures that these characters help each other overcome their weaknesses. (A Supporting Character expresses the meaning of the connected TSM or Event.)',
				type: 'Character',
			},
			{
				title: 'Event',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Event'),
				tooltip:
					'A Supporting Event captures the strengths or essence of the connected element. Best used with Characters or TSMs.',
				type: 'Event',
			},
			{
				title: 'TSM',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Theme'),
				tooltip:
					'A Supporting TSM captures what guides the connected element or reveals how the two elements work together harmoniously.',
				type: 'Theme',
			},
			{
				title: mainElementType === 'Character' ? 'Protagonist' : 'Arc',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Arc'),
				tooltip:
					'A Protagonist is the main character driving an Arc. (A Supporting Arc has a common goal as the connected element.)',
				type: 'Arc',
			},
		],
		[objectArray],
	)

	const opposingOptions = useMemo(
		() => [
			{
				title:
					mainElementType === 'Character'
						? 'Opponent'
						: mainElementType === 'Arc'
						? 'Antagonist'
						: 'Character',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Character'),
				tooltip:
					'An Opponent means that these characters attack each others weaknesses. (An Opposing Character is expresses the weaknesses of the connected TSM or Event.)',
				type: 'Character',
			},
			{
				title: 'Event',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Event'),
				tooltip:
					'An Opposing Event reveals the weaknesses of or key contrasts to the connected element. Best used with Characters or TSMs.',
				type: 'Event',
			},
			{
				title: 'TSM',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Theme'),
				tooltip:
					'An Opposing TSM captures conflict at the thematic level or reveals the tensions between the two elements.',
				type: 'Theme',
			},
			{
				title: mainElementType === 'Character' ? 'Antagonist' : 'Arc',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Arc'),
				tooltip:
					'An Antagonist is directly competing against the Protagonist for the same goal. (An Opposing Arc has a conflicting goal as the connected element.)',
				type: 'Arc',
			},
		],
		[objectArray],
	)

	const driverOptions = useMemo(
		() => { 
			const output = { 
				Character: {
					title: `"${recommendedDriver?.name}" Driver Type`,
					items:[{
						title: mainElementType === 'Character'
							? 'Ally'
							: mainElementType === 'Arc'
							? 'Protagonist'
							: 'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'An Ally captures that these characters help each other overcome their weaknesses. (A Supporting Character expresses the meaning of the connected TSM or Event.)',
						type: 'Character',
						structureTagIcon: "Supporting"
					},
					{
						title: mainElementType === 'Character'
							? 'Opponent'
							: mainElementType === 'Arc'
							? 'Antagonist'
							: 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Opponent means that these characters attack each others weaknesses. (An Opposing Character is expresses the weaknesses of the connected TSM or Event.)',
						type: 'Character',
						structureTagIcon: "Opposing",
						
					},],
					isTitle:true
					

				},
				Theme: {
					title: `"${recommendedDriver?.name}" Driver Type`,
					items:[{
						title:'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'A Supporting TSM captures what guides the connected element or reveals how the two elements work together harmoniously.',
						type: 'Theme',
						structureTagIcon: "Supporting",
						
					},
					{
						title: 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Opposing TSM captures conflict at the thematic level or reveals the tensions between the two elements.',
						type: 'Theme',
						structureTagIcon: "Opposing",
						
					},],
					isTitle:true

				},
				Arc: {
					title: `"${recommendedDriver?.name}" Driver Type`,
					items:[{
						title: mainElementType === 'Character' ? 'Protagonist' : 'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'A Protagonist is the main character driving an Arc. (A Supporting Arc has a common goal as the connected element.)',
						type: 'Arc',
						structureTagIcon: "Supporting"
					},
					{
						title: mainElementType === 'Character' ? 'Antagonist' : 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Antagonist is directly competing against the Protagonist for the same goal. (An Opposing Arc has a conflicting goal as the connected element.)',
						type: 'Arc',
						structureTagIcon: "Opposing"
					},],
					isTitle:true

				},
				Event: {
					title: `"${recommendedDriver?.name}" Driver Type`,
					items:[{
						title:'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'A Supporting Event captures the strengths or essence of the connected element. Best used with Characters or TSMs.',
						type: 'Event',
						structureTagIcon: "Supporting"
					},
					{
						title: 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Opposing Event reveals the weaknesses of or key contrasts to the connected element. Best used with Characters or TSMs.',
						type: 'Event',
						structureTagIcon: "Opposing"
					},],
					isTitle:true

				},
				Supporting: {
					title: 'Supporting',
					items: supportingOptions,
					isTitle: true,
				},
				Opposing: {
					title: 'Opposing',
					items: opposingOptions,
					isTitle: true,
				}

			}
			
			return output

		}, [supportingOptions, opposingOptions, recommendedDriver]
	)
	const menuOptions = useMemo(
		() => isSupporting
				? [
						{
							title: 'Supporting',
							items: supportingOptions,
							isTitle: true,
						},
						{
							title: 'Opposing',
							items: opposingOptions,
							isTitle: true,
						},
				  ]
				: [
						{
							title: 'Opposing',
							items: opposingOptions,
							isTitle: true,
						},
				  ],
		[supportingOptions, opposingOptions],
	)

	const createStaticConnection = async (newConnection) => {

		await performCreateConnectionMutation({
			client,
			newConnection,
			user
		}
		)
		user?.refetchUser()
	}

	const createStaticConnectionAndElement = async (newConnection,newElement,optimisticResponse) => {
			 await performCreateConnectionAndElement({
				client,
				newConnection,
				newElement,
				user,
				optimisticResponse,
				hasOrder:true, 
				broadcast:false
			})
			
	}

	const handleClick = async (destId, structureTag,newConnection) => {
	
		if (destId) {
			const sharedBeats = graphData.nodes[mainElementId].driverConnections.map(conn => { 
				const beatId = conn.sourceNode.__typename === "Beat" ? conn.sourceNode.id : conn.destNode.id
				const beat = graphData.nodes[beatId]
				return beat
				} ).filter((beat)=> {
				
				const sharedBeat = beat.driverConnections.find((driver) => {

					return [driver.sourceNode.id,driver.destNode.id].includes(destId) 
					
				})
				return sharedBeat
			}).map(beat => beat )

			
			if(!newConnection){
				const tempId = createNodeId("Relationship")
				newConnection = {
					id: tempId,
					description: '',
					arcStage: null,
					relName: 'RELATED',
					order: null,
					connectionType: 'Static',
					structureTag,
					arc: null,
					sourceNode: {
						id: mainElementId,
						__typename: graphData?.nodes[mainElementId]?.__typename,
					},
					destNode: {
						id: destId,
						__typename: graphData?.nodes[destId]?.__typename,
					},
					__typename: 'Relationship',
					beatsDriven: String(sharedBeats?.length || 0),
					sharedBeats,
					firstBeat: '',
					createdAt: new Date().toISOString()
					
				}
				dispatch(setNewConnId(tempId))
				handleClose()
			} 
			// const connectionCount = getConnectionCount(client)
			// dispatch(
			// 	setConnectionCount({
			// 		supportingCount: connectionCount.Supporting,
			// 		opposingCount: connectionCount.Opposing,
			// 		undefinedCount: connectionCount.Undefined,
			// 		total: connectionCount.Total
			// 	}),
			// )
					
			createStaticConnection(newConnection)
						
		}
	}

	return (
		<Popover anchorEl={isOpen} open={!!isOpen} onClose={handleClose}>
			<MenuContainer>
				<div className="container">
					{/* {driverOptions[connectionModalType.type].map((item, index) => (
						<MenuItem key={index} item={item} mainElementId = {mainElementId} onClick={handleClick} onClose={handleClose} connectionModalType={connectionModalType}/>
					))} */}
					<MenuItem item={driverOptions[connectionModalType.type]} mainElementId = {mainElementId} onClick={handleClick} onClose={handleClose} connectionModalType={connectionModalType} handleCreateConnectionAndElement={createStaticConnectionAndElement} />

				</div>
			</MenuContainer>
		</Popover>
	)
}

export default ConnectionModal
