import { useApolloClient } from '@apollo/client'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import orderBy from 'lodash/orderBy'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
	elementReordered,
	setBeatsInChapters,
	setLeftToggleTabs,
	setNoElementError,
	setRightToggleTabs,
	setServerError,
} from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { useCreateNodeMutation, useReorderNodesMutation } from '@lynit/shared/src/hooks'
import { createCacheElement, deleteCacheElement } from '@lynit/shared/src/utils/apollo'
import ListElement from '@lynit/layout/src/ui/ListElement'
import { CreateButton, Header, ListWrapper } from './styles'
import { createNodeId } from '@lynit/shared/src/utils/utils'
import elementBox from '@lynit/shared/src/images/elementBox.svg'

const ChapterTab = () => {
	const [isNodeCreated, setNodeCreated] = useState(false)
	const [filteringData, setFilteringData] = useState([])
	const [newNode, setNewNode] = useState(null)

	const {
		state: { deleteNode, scrollPosition,currentStory, beatsInChapters },
		dispatch: sharedDispatch,
	} = useContext(sharedContext)
	const {
		currentElement,
		deepModeElement,
		serverError,
		noElementError,
		reorderedPosition,
	} = useContext(beatsDataStateContext);
	const [expandedElementId, setExpandedElementId] = useState()
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const { user } = useContext(userStateContext)
	const { isMobile, createLog, toastHandler } = useContext(systemStateContext)
	const graphData = useContext(graphDataStateContext)

	const [performCreateNodeMutation, { data: createdNode }] = useCreateNodeMutation(['Chapter'])
	const [reorderNodes] = useReorderNodesMutation()

	const serverErrorInterval = useRef()
	const scrollRef = useRef()
	const client = useApolloClient()

	useEffect(() => {
		setExpandedElementId(currentStory?.lastOpenedChapter)
	}, [currentStory?.lastOpenedChapter])

	// useEffect(() => {
	// 	if (currentElement.includes('chp-')) {
	// 		setExpandedElementId(currentElement)
	// 	}
	// }, [currentElement])

	useEffect(() => {
		if (
			reorderedPosition?.type === 'chapter' &&
			reorderedPosition.source.index !== reorderedPosition.destination.index
		) {
			setFilteringData(
				arrayMove(
					filteringData,
					reorderedPosition.source.index,
					reorderedPosition.destination.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [reorderedPosition])

	useEffect(() => {
		if (isNodeCreated) {
			; (async () => {
				const mutationData = []

				filteringData.forEach(node => {
					mutationData.push({
						id: node.id,
						order: String(node.order + 1),
					})
				})

				await reorderNodes({
					variables: {
						nodeType: 'Chapter',
						newNodeOrder: mutationData,
					},
				})
					.then(res => {
						if (res && serverError) {
							beatsDataDispatch(setServerError(false))
							clearInterval(serverErrorInterval.current)
						}
					})
					.catch(async err => {
						if (err.message.includes('Cannot update')) {
							beatsDataDispatch(setNoElementError(true))
						}
						if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
							beatsDataDispatch(setServerError(true))
						}
					})
				setNodeCreated(false)
			})()
		}
	}, [isNodeCreated])

	useEffect(() => {
		const currentElements = graphData.nodesByType?.['Chapter'] || []
		const elements = currentElements.map(element => {
			return { ...element, order: +element.order }
		})
		setFilteringData(orderBy(elements, ['order'], ['asc']))
	}, [graphData.nodesByType])

	// display updated position before getting response from backend while reorder elements
	useEffect(() => {
		if (
			reorderedPosition?.type === 'element' &&
			reorderedPosition.source.index !== reorderedPosition.destination.index
		) {
			setFilteringData(
				arrayMove(
					filteringData,
					reorderedPosition.source.index,
					reorderedPosition.destination.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [reorderedPosition])

	const addNodeFunc = async () => {

		const tempObj = {
			id: createNodeId("Chapter"),
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: '0',
			__typename: "Chapter",
			name: "New Chapter",
			description: '',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			firstBeat: '',
			coreElement: 'false',
			storyText: '',
			number:"0",
			wordCount:"0"
		}
		const optimisticResponse = {}
		
		optimisticResponse[`create${tempObj.__typename}`] = tempObj

		setNewNode(tempObj)
		
		//const elem = document.getElementById(`chapters-container`)

		await performCreateNodeMutation({
			node: {
					id: tempObj.id,
					name: tempObj.name,
					description: tempObj.description,
					number: tempObj.number,
					order: tempObj.order,
			},
			optimisticResponse,
			tempObj

		})
			.then(async res => {
				
				setNodeCreated(true)
				
			})
			.catch(e => {
				console.error(e)
				setNewNode(null)
			})
	}

	useEffect(() => {
		if (filteringData.length) {
			let totalBeats = 0
			filteringData.forEach(chapter => {
				totalBeats += chapter.beatConnections.length
			})
			sharedDispatch(setBeatsInChapters(totalBeats))
		} else {
			sharedDispatch(setBeatsInChapters(0))
		}
	}, [filteringData])

	useEffect(() => {
		if (!expandedElementId) {
			scrollRef.current.scrollTop = scrollPosition
		}
	}, [expandedElementId])

	return (
		<>
			<Header className="draggg">
				<span className="chapter-view-title">
					<span className="chapters-title">Chapter Planner</span>
					<span
						onMouseEnter={() => {
							createLog(
								'Total Beats Count Hovered',
								`{"location":"ChapterView"}`,
								'ChapterView',
								null,
							)
						}}
					>
						Beats in Chapters: {beatsInChapters}
					</span>
				</span>
				<CreateButton
					data-testid="create-button"
					disabled={serverError || noElementError}
					onClick={async () => {
						await addNodeFunc()
						createLog(
							`Element Creation Attempted`,
							`{"workflowStep":${1},"elementType":"Chapter"}`,
							'ElementList',
							'Element Creation',
						)
					}}
				>
					+ New Chapter
				</CreateButton>
			</Header>
			{filteringData?.length === 0 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '20%',
					}}
				>
					<img style={{ width: '70%' }} src={elementBox} alt="BoxIcon" />
					<p>There are no Chapters to show</p>
				</div>
			)
			}
			<ListWrapper ref={scrollRef} id="chapter-list-container">
				{expandedElementId ? (
					<ListElement
						className="wewe"
						setExpandedElementId={setExpandedElementId}
						expandedElementId={expandedElementId}
						key={expandedElementId}
						id={expandedElementId}
						name={graphData.nodes[expandedElementId]?.name ?? ''}
						description={graphData.nodes[expandedElementId]?.description ?? ''}
						type={'Chapter'}
						number={graphData.nodes[expandedElementId]?.number ?? 0}
						newNode={newNode}
						setNewNode={setNewNode}
						deleteNode={deleteNode}
						active={'Chapter'}
						filteringData={filteringData}
						setServerError={setServerError}
						serverErrorInterval={serverErrorInterval}
						setNoElementError={setNoElementError}
						isRightPanel={true}
					/>
				) : (
					<SortableContext
						items={filteringData.map(element => element?.id)}
						strategy={verticalListSortingStrategy}
					>
						{filteringData?.map(element => (
							<ListElement
								className="wewe"
								setExpandedElementId={setExpandedElementId}
								expandedElementId={expandedElementId}
								key={element.id}
								id={element?.id}
								name={element.name}
								description={element?.description}
								type={'Chapter'}
								number={element.number}
								newNode={newNode}
								setNewNode={setNewNode}
								deleteNode={deleteNode}
								active={'Chapter'}
								filteringData={filteringData}
								setServerError={setServerError}
								serverErrorInterval={serverErrorInterval}
								setNoElementError={setNoElementError}
								isRightPanel={true}
								scrollRef={scrollRef}
							/>
						))}
					</SortableContext>
				)}
			</ListWrapper>
		</>
	)
}

export default ChapterTab
