/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
import { sortNodesByCreatedAt } from '@lynit/shared/src/utils/utils'
import _ from 'lodash'

const formattedData = data => {
	let desc = ''
	if (data) {
		try {
			desc = JSON.parse(data)
			desc = [...desc?.ops].map(ele => {
				let descData = JSON.stringify(ele)
				if (descData.includes('\\n') && !descData.includes('list')) {
					descData = descData.replace(/\\n/g, ' ')
					return JSON.parse(descData)
				}
				return JSON.parse(descData)
			})
		} catch (err) {
			desc = [{ insert: data.replace(/\n/g, ' ') }]
		}
		return desc
	}
	return []
}

const sortNodesByOrder = (a, b) => {
	if (Number(a.order) < Number(b.order)) {
		return -1
	}
	if (Number(a.order) > Number(b.order)) {
		return 1
	}
	return 0
}

const buildStoryQuill = async (quill, graphData, storyName) => {
	

	if (quill) {
		const elementDeltas = {}
		const elementNames = ["Arc","Character","Event","Theme", "Note","Chapter"]
		elementNames.forEach(elementType => {
			const elements =  graphData?.nodesByType[elementType].sort(sortNodesByOrder)
			
			elementDeltas[elementType] = []
			

			elements.forEach(element => {
				const staticConnections = []
				const elementNotes = []
				const driverBeats = []
				const beatConnnections = []
				const noteConnections = []
				
				
				if(element.__typename === "Note"){
					const noteLinksArray = []
					const notesData =
					element?.noteConnections?.slice()?.sort(sortNodesByCreatedAt)?.map(link => {
								const { id } = link.destNode?.__typename === 'Note' ? link.sourceNode : link.destNode

								return graphData?.nodes[id].__typename === 'Chapter'
								? `Chapter ${
										graphData?.nodes[id].number || 0
								  }: ${graphData?.nodes[id].name} `
								: graphData?.nodes[id].name
							})|| []
		
						// if driverData contains driver names then object will be created to diaplay driver name, and push it into driverArray
						if (notesData.length) {
							;[...new Set(notesData)].forEach(linkName => {
								noteLinksArray.push(
									{ insert: ` ${linkName}` },
									{
										insert: `\n`,
										attributes: { bold: true, indent: 2, list: 'bullet' },
									},
								)
							})
						}
	
						elementNotes.push(
							...formattedData(element.contents),
			
							{ attributes: { list: 'bullet' }, insert: '\n' },
							{ insert: 'Links' },
							{ insert: '\n', attributes: { indent: 1, list: 'bullet' } },
							...noteLinksArray,
						)
					

				} else {
					element?.noteConnections?.slice()?.sort( sortNodesByCreatedAt)?.forEach(conn => {

						const noteId = conn.destNode?.id !== element.id ? conn.destNode?.id : conn.sourceNode?.id

						// noteLinksArray is array of object, where each object represent an element linked to note
						const noteLinksArray = []
		
						const note =  graphData.nodes[noteId]
	
		
						// notesData is array which contains element names of element linked to note
						const notesData =
						note?.noteConnections?.map(link => {
								const { id } =
									link.destNode?.__typename === 'Note' ? link.sourceNode : link.destNode
								return graphData?.nodes[id].__typename === 'Chapter'
								? `Chapter ${
										graphData?.nodes[id].number || 0
								  }: ${graphData?.nodes[id].name} `
								: graphData?.nodes[id].name
							})|| []
		
						// if driverData contains driver names then object will be created to diaplay driver name, and push it into driverArray
						if (notesData.length) {
							;[...new Set(notesData)].forEach(linkName => {
								noteLinksArray.push(
									{ insert: ` ${linkName}` },
									{
										insert: `\n`,
										attributes: { bold: true, indent: 2, list: 'bullet' },
									},
								)
							})
						}
	
						elementNotes.push(
							...formattedData(note.contents),
			
							{ attributes: { list: 'bullet' }, insert: '\n' },
							{ insert: 'Links' },
							{ insert: '\n', attributes: { indent: 1, list: 'bullet' } },
							...noteLinksArray,
						)
	
					})

				}
				
				

				//staticConnections
				element?.staticConnections?.slice()?.sort( sortNodesByCreatedAt)?.forEach(conn => {
					const otherElementId =  conn.destNode?.id !== element.id ? conn.destNode?.id : conn.sourceNode?.id
					staticConnections.push(
						{
							insert: `${
								 graphData.nodes[otherElementId]?.name
							} - `,
						},
						...formattedData(conn.description),
						{ insert: '\n', attributes: { indent: 0, list: 'bullet' } },
					)
				})


				//driverBeats
				console.log("element?.driverConnections?.slice()?.sort(sortNodesByOrder)",element?.driverConnections?.slice()?.sort(sortNodesByOrder))
				element?.driverConnections?.slice()?.sort(sortNodesByOrder)?.map(connection => {
					
		
					// driverArray is array of object, where each object represent driver of parent element of a beat
					const driverArray = []
	
					// charTempBeatElement is beat available in either sourceNode or destNode of each driver connection
					const beat = connection.destNode?.__typename !== 'Beat' ? graphData.nodes[connection.sourceNode?.id] : graphData.nodes[connection.destNode?.id]

	
					// driverData is array which contains driver names of parent element of a beat
					const driverData =
					beat?.driverConnections?.map(driver => {
							const { id } =
								driver.destNode?.__typename === 'Beat' ? driver.sourceNode : driver.destNode
							return graphData.nodes[id].name
						})|| []
	
					// if driverData contains driver names then object will be created to diaplay driver name, and push it into driverArray
					if (driverData.length) {
						;[...new Set(driverData)].forEach(driverItem => {
							driverArray.push(
								{ insert: ` ${driverItem}` },
								{
									insert: `\n`,
									attributes: { bold: true, indent: 2, list: 'bullet' },
								},
							)
						})
					}
	
					// driverBeats contains object that contains information the beats of character.
					// each object contains parent Arc/Character name, description of a beat, and driver connections of parent of a beat
					driverBeats.push(
						...formattedData(beat?.description),
						{
							insert: `\n`,
							attributes: { bold: true, list: 'bullet', indent: 0 },
						},
						{ insert: 'Drivers:', attributes: { header: 3 } },
						{ insert: '\n', attributes: { indent: 1, list: 'bullet', bold: true } },
						// driverArray contains object where each object is used to represent driver of parent element of a beat
						...driverArray,
					)
				})

				element?.beatConnections?.slice()?.sort(sortNodesByOrder)?.map(connection => {
										
					// driverArray is array of object, where each object represent driver of parent element of a beat
					const driverArray = []
	
					// charTempBeatElement is beat available in either sourceNode or destNode of each driver connection
					const beat = connection.destNode?.__typename !== 'Beat' ? graphData.nodes[connection.sourceNode?.id] : graphData.nodes[connection.destNode?.id]

	
					// driverData is array which contains driver names of parent element of a beat
					const driverData =
					beat?.driverConnections?.map(driver => {
							const { id } =
								driver.destNode?.__typename === 'Beat' ? driver.sourceNode : driver.destNode
							return graphData.nodes[id].name
						})|| []
	
					// if driverData contains driver names then object will be created to diaplay driver name, and push it into driverArray
					if (driverData.length) {
						;[...new Set(driverData)].forEach(driverItem => {
							driverArray.push(
								{ insert: ` ${driverItem}` },
								{
									insert: `\n`,
									attributes: { bold: true, indent: 2, list: 'bullet' },
								},
							)
						})
					}
	
					// driverBeats contains object that contains information the beats of character.
					// each object contains parent Arc/Character name, description of a beat, and driver connections of parent of a beat
					beatConnnections.push(
						...formattedData(beat?.description),
						{
							insert: `\n`,
							attributes: { bold: true, list: 'bullet', indent: 0 },
						},
						{ insert: 'Drivers:', attributes: { header: 3 } },
						{ insert: '\n', attributes: { indent: 1, list: 'bullet', bold: true } },
						// driverArray contains object where each object is used to represent driver of parent element of a beat
						...driverArray,
					)
				})
			

				

				const desc = formattedData(element.description) || []
				
				let obj = []
				if (element.__typename === "Chapter"){

					obj = [
						{
							attributes: { bold: true },
							insert: `\n${element.__typename} ${element.number}: ${element.name}`,
						},
						{ insert: '\n', attributes: { header: 1 } },
						{ insert: '\n' },
						{ insert: 'Description: ' },
						{ insert: '\n', attributes: { header: 2 } },
						...desc,
						{ insert: '\n\n' },
						{ attributes: { header: 2 }, insert: 'Notes:\n' },
						...elementNotes,
						{ insert: '\n' },
						{ insert: '\n', attributes: { header: 2 } },
			
						{ insert: 'Beats: ' },
						{ insert: '\n', attributes: { header: 2 } },
						...beatConnnections,
						{ insert: '\n\n' },
					]

				} else if (element.__typename === "Note") {
					obj = [...elementNotes]
				} else {

					obj = [
						{
							attributes: { bold: true },
							insert: `\n${element.__typename}: ${element.name}`,
						},
						{ insert: '\n', attributes: { header: 1 } },
						{ insert: '\n' },
						{ insert: 'Description: ' },
						{ insert: '\n', attributes: { header: 2 } },
						...desc,
						{ insert: '\n\n' },
						{ attributes: { header: 2 }, insert: 'Notes:\n' },
						...elementNotes,
						{ insert: '\n' },
						{ insert: '\n', attributes: { header: 2 } },
			
						{ insert: 'Connections: ' },
						{ insert: '\n', attributes: { header: 2 } },
						...staticConnections,
						{ insert: 'Beats: ' },
						{ insert: '\n', attributes: { header: 2 } },
						...driverBeats,
						{ insert: '\n\n\n' },
					]

				}

				
				elementDeltas[elementType].push(...obj)

			})
			
			


		})



		const title = [
			{
				insert: storyName?.length > 0 ? storyName : 'My Story',
				attributes: { size: 'large' },
			},
			{
				insert: '\n',
				attributes: { align: 'center', header: 1 },
			},
		]

		const document = await quill.setContents([
			...title,
			...elementDeltas["Arc"],
			...elementDeltas["Character"],
			...elementDeltas["Event"],
			...elementDeltas["Theme"],
			...elementDeltas["Chapter"],
			{ insert: `\nNotes`, attributes: { bold: true } },
			{ insert: '\n', attributes: { header: 1 } },
			...elementDeltas["Note"],
		])
		return document
	}
}

export default buildStoryQuill
