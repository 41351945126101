export const Fonts = [
	'Arial',
	'Helvetica',
	'Gill-Sans',
	'Lucida',
	'Helvetica-Narrow',
	'sans-serif',
	'Times',
	'Times-New-Roman',
	'Palatino',
	'Bookman',
	'New-Century-Schoolbook',
	'serif',
	'Andale-Mono',
	'Courier-New',
	'Courier',
	'Lucidatypewriter',
	'Fixed',
	'monospace',
	'Comic-Sans',
	'Zapf-Chancery',
	'Coronetscript',
	'Florence',
	'Parkavenue',
	'cursive',
	'Impact',
	'Arnoldboecklin',
	'Oldtown',
	'Blippo',
	'Brushstroke',
	'fantasy',
]

export const Sizes = [8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 32, 42, 54, 68, 84, 98]
