import React, { useContext, useEffect, useMemo, useState } from 'react'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { setDivingDeep, setRightToggleTabs } from '@lynit/shared/src/state/actions'
import CollapsiblePanel from '@lynit/shared/src/ui/CollapsiblePanel'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import NotesList from '@lynit/layout/src/ui/NotesList'
import ChapterTab from '@lynit/layout/src/ui/ChapterTab'

import {
	CollapseDiv,
	ElementContainer,
	ElementListContainer,
	IconContainer,
	Tab,
	TabsContainer,
	TabText,
} from './styles'

import noteIcon from '@lynit/shared/src/images/notesIcon.svg'
import chapterIcon from '../../images/chapterIcon.svg'

const RightSidePanel = ({ isChapterTabOpen }) => {

	const [currentTab, setCurrentTab] = useState('Notes')
	const [noteNodes, setNoteNodes] = useState([])

	const {
		state: { rightToggleTabs },
		dispatch: sharedDispatch,
	} = useContext(sharedContext)
	const { currentElement, deepModeElement } = useContext(beatsDataStateContext)
	const graphData = useContext(graphDataStateContext)

	const items = [{ name: 'Notes', icon: noteIcon }]

	

	useEffect(() => {
		if (currentElement) {
			const deepModeElementData = graphData?.nodes?.[currentElement]?.noteConnections
				?.map(data => {
					if (data?.sourceNode?.__typename === 'Note') {
						return graphData?.nodes?.[data?.sourceNode?.id]
					}
					if (data?.destNode?.__typename === 'Note') {
						return graphData?.nodes?.[data?.destNode?.id]
					}
					
				})
				?.filter(data => data)
			setNoteNodes(deepModeElementData ?? [])
		} else {
			setNoteNodes(graphData?.nodesByType?.Note ?? [])
		}
	}, [currentElement, graphData])

	// useEffect(() => {
	// 	if (currentElement.includes('chp-')) {
	// 		setCurrentTab('Chapters')
	// 	} else {
	// 		setCurrentTab(items[0].name)
	// 	}
	// }, [currentElement])

	const getCount = name => {
		if (name === 'Notes') {
			return noteNodes?.length ?? ''
		}
		if (name === 'Chapters') {
			return graphData?.nodesByType?.['Chapter']?.length ?? ''
		}
		return ''
	}

	const getText = (name, count) => {
		if (!count) {
			if (currentTab === name) {
				return name
			}
			if (deepModeElement) {
				return String(getCount(name))
			}
			return String(getCount(name))
		}
		if (rightToggleTabs || currentTab !== name) {
			return ''
		}
		if (deepModeElement) {
			return String(getCount(name))
		}
		return String(getCount(name))
	}

	return (
		<ElementListContainer id= "tourStart3" className="tour-step-1">
			<CollapsiblePanel
				toggle={rightToggleTabs}
				handleToggle={setRightToggleTabs}
				StyledDiv={CollapseDiv}
			/>

			<ElementContainer className="draggg" rightToggleTabs={rightToggleTabs} id="element-container">
				<div
					style={{
						background: '#FFFFFF',
						borderRadius: '4px',
						margin: '10px 5px',
						height: 'calc(100% - 20px)',
					}}
				>
					{currentTab === 'Notes' && (
						<NotesList
							deepModeElementName={graphData?.nodes?.[currentElement]?.name}
							noteNodes={noteNodes}
						/>
					)}
				</div>
			</ElementContainer>

			<TabsContainer className="demo">
				{items?.map(
					type => (
						<div
							key={type.name}
							style={{ cursor: 'pointer' }}
							onClick={() => {

								setCurrentTab(type.name)
								if (type.name === currentTab) {
									sharedDispatch(setRightToggleTabs(!rightToggleTabs))
								} else if (!rightToggleTabs) {
									sharedDispatch(setRightToggleTabs(true))
								}
							}}
						>
							<Tab className="mytab arc_tab" isActive={type.name === currentTab}>
								<ToolTip title={type.name} placement="right" arrow enterDelay={1500}>
									<IconContainer className="icon-container" isSelected={type.name === currentTab}>
										<img src={type.icon} />
									</IconContainer>
								</ToolTip>

								<TabText isActive={type.name === currentTab}>
									<span className="chapetername">
										{getText(type.name) ? <span>{getText(type.name)}</span> : ''}
										{getText(type.name, true) ? <span>{getText(type.name, true)}</span> : ''}
									</span>
								</TabText>
							</Tab>
						</div>
					),
				)}
			</TabsContainer>
		</ElementListContainer>
	)
}

export default RightSidePanel
