/* eslint-disable prettier/prettier */
/* eslint-disable import/no-duplicates */
import React from 'react'
import { getNodeIcon } from '@lynit/shared/src/utils/utils'
import { ElementContainer, IconContainer } from '@lynit/shared/src/ui/DriverModal/styles'

const Element = ({
	isRightSidePanel,
	e,
	id,
	name,
	type,
	createConnection,
	setFilterInput,
	setConnectionModal,
	isArc,
	isNote,
	connectionArc,
	sourceId,
	sourceNode,
	createLog,
}) => {
	const trimName = name => {
		if (name?.length > 25) {
			return `${name.slice(0, 25)}...`
		} else {
			return name
		}
	}

	return (
		<ElementContainer
			onClick={async () => {
				setConnectionModal(false)
				setFilterInput('')

				if (isArc) {
					if (isRightSidePanel) {
						await createConnection(
							id,
							sourceId,
							connectionArc?.id,
							connectionArc?.name,
							connectionArc,
						)
					} else {
						await createConnection(
							sourceId,
							sourceNode,
							id,
							e,
							connectionArc?.id,
							connectionArc?.name,
						)
					}
				}
				if (isNote) {
					await createConnection(id)
				}
				if (!isArc && !isNote) {
					await createConnection(id, true)
				}
				createLog(
					`Connection Creation Element Selected`,
					`{"workflowStep":${2},"connectionType":"Note"}`,
					'Link Note Connection Modal',
					'Connection Creation',
				)
			}}
		>
			<IconContainer isArc={isArc} type={type}>
				<img src={getNodeIcon(type)} />
			</IconContainer>
			{isArc || isNote ? (
				<p style={{ fontSize: '0.7rem', width:'100px' }}>{trimName(name)}</p>
			) : (
				<p>{trimName(name)}</p>
			)}
		</ElementContainer>
	)
}

export default Element
