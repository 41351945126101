/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'
import Element from './Element'
import { InputContainer } from './styles'
import LensIcon from '@lynit/shared/src/images/emptyLens.svg'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'

const useStyles = makeStyles(() => ({
	root: {
		width: '180px',
		height: '200px',
		padding: '0 10px',
	},
}))

const LinkNoteModal = React.memo(
	({
		isRightSidePanel,
		objectList,
		createConnection,
		isActive,
		currentId,
		setConnectionModal,
		connectionArc,
		sourceId,
		sourceNode,
		createLog,
		position,
		elementName
	}) => {
		const classes = useStyles()
		

		const nodeList = objectList?.filter(
			e => {
				return ['Arc', 'Event','Theme', 'Character', 'Chapter'].includes(e.__typename) && e.id !== currentId
				}
		).sort((a,b) => {
			return a.__typename.localeCompare(b.__typename) || a.name.localeCompare(b.name) 
			
		})

		const [objectArray, setObjectArray] = useState(nodeList)
		const [filterInput, setFilterInput] = useState('')

		//link node
		
		// useEffect(() => {
		// 		if(isActive){
		// 			document.getElementById('linkSearchInput')?.focus();
		// 		}
				
			
		// 	console.log("linkNoteModal loaded")
		// 	console.log("document.getElementById('linkSearchInput')",document.getElementById('linkSearchInput'))
		// }, [isActive])

		useEffect(() => {
			setObjectArray(
				nodeList.filter(
					node => node?.name?.toLowerCase()?.includes(filterInput),
				),
			)
			
			
		}, [objectList, filterInput])

		

		return (
			<div className="addDriver">
				<Popover
					id="connection-modal"
					anchorEl={isActive}
					transformOrigin={{ vertical: position === 'top' ? 'bottom' : 'top', horizontal: 'left' }}
					open={Boolean(isActive)}
					onClose={() => setConnectionModal(false)}
					classes={{ paper: classes.root }}
				>
					<span
						style={{
							alignItems: 'center',
							fontFamily: 'Source Sans Pro',
							fontWeight: '700',
							fontSize: '14px',
							letterSpacing: '0.05em',
							marginBottom: '5px',
						}}
					>
						Connect to:
					</span>
					<InputContainer className="container">
						<img src={LensIcon} alt="LensIcon" />
						<input
							autoFocus
							id={"linkSearchInput"}
							defaultValue={filterInput}
							type="text"
							placeholder="Search"
							onChange={e => setFilterInput(e.target.value.toLowerCase())}
						/>
					</InputContainer>

					{objectArray?.length > 0 ? objectArray?.filter(item => {
					if(item.name !== elementName) {
						return item
					}})
						.map(e => (
							<Element
									isRightSidePanel={isRightSidePanel}
									isNote={true}
									isArc={false}
									connectionArc={connectionArc}
									sourceId={sourceId}
									sourceNode={sourceNode}
									key={e?.id}
									name={e.__typename === 'Chapter' ? `Ch ${e.number}: ${e.name}` : e.name}
									type={e.__typename}
									id={e?.id}
									e={e}
									createConnection={createConnection}
									setFilterInput={setFilterInput}
									setConnectionModal={setConnectionModal}
									createLog={createLog}
								/>
						  ))
						: 'No results'}
				</Popover>
			</div>
		)
	},
)

export default LinkNoteModal