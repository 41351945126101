import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState
} from 'react'
import { v4 as uuid } from 'uuid'
import { useApolloClient } from '@apollo/client'
import LogRocket from 'logrocket'
import { datadogRum } from '@datadog/browser-rum'

import { useDeleteNodeMutation, useGetUserQuery, useGetUserStoryQuery, useGetVersionQuery } from '../hooks'

import {
	setIsOnline,
	setWasOffline,
	setIsTourOpen,
	setIsDifferentVersion,
	setCurrentStory,
	setUser,
} from './actions'
import { sharedReducer } from './sharedReducer'

import { useIdleTimer } from 'react-idle-timer'
import { userDispatchContext, userStateContext, setUserData } from './userProvider'
import { systemDispatchContext, systemRelatedData } from './systemProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from './beatsProvider'

export const sharedContext = createContext()

Array.prototype.move = function (from, to) {
	this.splice(to, 0, this.splice(from, 1)[0]);
  };

const LSIsTourOpen = localStorage.getItem('tour')
	? JSON.parse(localStorage.getItem('tour')).LSIsTourOpen
	: false

const initialState = {
	flow: '',
	isSummaryView: false,
	story: sessionStorage?.getItem('story'),
	unseenRecommendationCount: {
		total: 0,
		craft: 0,
		speed: 0,
		habit: 0,
	},
	isSaving: false,
	recommendationId: '',
	currentRecommendation: null,
	isRecommendationShown: false,
	currentStory: null,
	deleteStory: false,
	isTourOpen: LSIsTourOpen || false,
	isExporting: false,
	beatsInChapters: 0,
	tutorialId: '',
	tutorialSetId: '',
	clickedValueProp: null,
	leftToggleTabs: true,
	rightToggleTabs: false,
	openToolTip: null,
	cacheConnData: {
		description: '',
		newConnId: '',
		selection: null,
	},
	isDivingDeep: false,
	editorData: null,
	activeElement: {
		id: null,
		type: null,
	},
	movedPosition: null,
	isUserSubscribed: false,
	isModalOpen:false,
	connectionCount: { supportingCount: 0, opposingCount: 0 },
	activeConnections: {
		isActive: false,
		elementId: null,
		elementType: null
	},
	scrollPosition: {
		elementList: 0,
		chapterTab: 0
	},
	isChapterViewExpanded: false,
	isVisualizationExpanded: false,
	contextDeepModeConns: []
}

export const SharedContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(sharedReducer, initialState);
	const user = useContext(userStateContext)
	const userDataDispatch = useContext(userDispatchContext)
	const systemDataDispatch = useContext(systemDispatchContext)
	const beatsRelatedData = useContext(beatsDataStateContext)

	const { data: userData, loading: loadingUser } = useGetUserQuery({ fetchPolicy: 'cache-first' })
	const { data: storyData, refetch: refetchStories } = useGetUserStoryQuery({ fetchPolicy: 'cache-first' })
	const {
		loading: loadingVersion,
		data: dataVersion,
		refetch: refetchVersion
	} = useGetVersionQuery()

	const [deleteNode] = useDeleteNodeMutation()

	const client = useApolloClient()

	const [isActive, setActive] = useState(null)

	const nIntervId = useRef()

	//BEACON LOGIC
	//loads correct support button on login and on routes that don't require auth
	useEffect(() => {
		if (!localStorage.token) {
			if (window.Beacon) {
				window.Beacon('logout')

				if (!window.Beacon('info')) {
					window.Beacon('init', process.env.REACT_APP_BEACON_SECRET)
				}
			}
		}
	}, [localStorage.token])

	//makes support button load on main dashboard if reloaded page or entered to url with token in localStorage
	useEffect(() => {
		if (localStorage.token && userData?.user && window.Beacon) {
			if (!window.Beacon('info')) {
				window.Beacon('init', process.env.REACT_APP_BEACON_SECRET)
			}
			window.Beacon('identify', { name: userData?.user?.name, email: userData?.user?.email })
		}
	}, [userData?.user?.email])

	//NETWORK MGMT LOGIC
	const updateNetwork = () => {
		if (window.navigator.onLine) {
			systemDataDispatch(setIsOnline(window.navigator.onLine))
			setTimeout(() => systemDataDispatch(setWasOffline(false)), 5000)
		}
		if (!window.navigator.onLine) {
			systemDataDispatch(setWasOffline(true))
			systemDataDispatch(setIsOnline(false))
		}
	}

	useEffect(() => {
		window?.addEventListener('offline', updateNetwork)
		window?.addEventListener('online', updateNetwork)
		return () => {
			window.removeEventListener('offline', updateNetwork)
			window.removeEventListener('online', updateNetwork)
		}
	}, [])

	//CLIENT_VERSION !== SERVER_VERSION LOGIC
	useEffect(() => {
		//reactour isFirstLogin logic to decide if render tour or not
		if (userData?.user?.isFirstLogin) {
			if (window.innerWidth !== 416) {
				dispatch(setIsTourOpen(true))
			}
		} else {
			dispatch(setIsTourOpen(false))
			localStorage.removeItem('tour')
		}
		
	}, [userData?.user?.isFirstLogin, window.innerWidth])

	useEffect(() => {
		if (!loadingUser) {
			if (!userData || !userData?.user?.active) {
				localStorage.clear()
				client.cache.reset()
			} else {
				userDataDispatch(setUser(userData?.user))
			}
		}
	}, [userData, loadingUser])

	const onIdle = () => {
		clearInterval(nIntervId.current)
	}

	const onActive = () => {
		clearInterval(nIntervId.current)
		nIntervId.current = setInterval(() => {
			user?.refetchUser()
			refetchVersion()
			refetchStories()
		}, 30000)
	}

	useEffect(() => {
		if (user?.user) {
			setTimeout(() => {
				user?.refetchUser()
				refetchVersion()
				refetchStories()
			})
			onActive()
			return () => {
				onIdle()
			}
		}
	}, [user?.user])

	useIdleTimer({ timeout: 60000, onIdle, onActive })

	useEffect(() => {
		if (dataVersion && !loadingVersion) {
			//user logged in
			if (!localStorage.version && dataVersion.version) {
				localStorage.setItem('version', dataVersion.version)
			} // user is in latest version
			if (localStorage.version === dataVersion.version) {
				systemDataDispatch(setIsDifferentVersion(false))
			} //user is in old version
			if (localStorage.version !== dataVersion.version && dataVersion.version) {
				localStorage.setItem('version', dataVersion.version)
				systemDataDispatch(setIsDifferentVersion(true))
				setTimeout(() => window.location.reload(), 5000)
			}
		}
	}, [dataVersion])

	useEffect(() => {
		const story = storyData?.stories?.find(e => e?.id === beatsRelatedData?.currentStoryId)
		dispatch(setCurrentStory(story ?? ''))
	}, [storyData])

	useEffect(() => {
		refetchStories()
	}, [])

	useEffect(() => {
		if (!loadingUser) {
			if (userData?.user) {
				if (
					!LogRocket?._logger?._identifyCalls ||
					LogRocket?._logger?._identifyCalls <= 7 ||
					user?.user?.email !== userData?.user?.email
				) {
					LogRocket.identify(userData.user?.id, {
						name: userData.user?.name,
						email: userData.user?.email,

						// Add your own custom user variables here, ie:
						subscriptionType: ''
					})
				}
				if (!datadogRum.getUser().email || user?.user?.email !== userData?.user?.email) {
					datadogRum.setUser({
						id: userData.user?.id,
						name: userData.user?.name,
						email: userData.user?.email
					})
				}

				if (user?.user?.email !== userData?.user?.email) {
					userDataDispatch(setUser(userData?.user))
				}
			}
		}

		// eslint-disable-next-line
	}, [userData?.user?.email, loadingUser])



	useEffect(() => {
		
		if(!localStorage.getItem('su')){
			localStorage.setItem('su', `${uuid()}`)
		}
	}, [localStorage.getItem('su')])


	
	useEffect(() => {
		if (
			(isActive && user?.user?.subscriptionStatus?.status === 'active') ||
			user?.user?.subscriptionStatus?.status === 'trialing'
		) {
			setActive(false)
		}
		if (
			(!isActive && user?.user?.subscriptionStatus?.status === 'past_due') ||
			user?.user?.subscriptionStatus?.status === 'canceled'
		) {
			setActive(true)
		}
	}, [user?.user?.subscriptionStatus?.status])

	return (
		<sharedContext.Provider
			value={{ state: { ...state, isActive, deleteNode }, dispatch }}
		>
			{children}
		</sharedContext.Provider>
	)
}
