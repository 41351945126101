import styled from 'styled-components'
import { colorStyles } from '../../utils/commonStyles'

const Container = styled.div`
	height: 3.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 0.5px solid #c4c4c4;
	justify-content: space-between;
	background: white;
	width: 100vw;
	background-color: white;
	@media (max-width: 600px) {
		height: 30px;
		margin-bottom: 0rem;
	}
`

const MenuContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`

const FrameOption = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 24px;
`

const NameButton = styled.div`
	width: 2.3rem;
	height: 2.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #c4c4c4;
	color: white;
	font-weight: bold;
	border-radius: 0.5rem;
	border: none;
	object-fit: contain;
	cursor: pointer;
	@media (max-width: 880px) {
		width: 2rem;
		height: 2rem;
	}

	@media (max-width: 600px) {
		width: 23px;
		height: 23px;
		font-size: 14px;
	}
`

const LogoutButton = styled.div`
	width: 12rem;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	object-fit: contain;
	z-index: 999;
	cursor: pointer;
	text-decoration: none;

	p {
		text-align: left;
		word-break: normal;
		width: 100%;
		padding-left: 8%;
		margin: 0;
	}
	@media (max-width: 880px) {
		width: auto;
		font-size: 0.7rem;
	}
`

const HeaderLeftContainer = styled.div`
	width: auto;
	height: auto;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	margin-right: 0rem;
	padding-left: 0.5rem;

	@media (min-width: 601px) {
		.logo-container {
			position: relative;
			::after {
				position: absolute;
				content: '';
				height: 13px;
				border: 1px solid #666666;
				right: -5px;
				top: 50%;
				transform: translateY(-50%);
			}
			margin-right: 15px;
		}
	}
`

const MenuContent = styled.div`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const ProfileOptions = styled.div`
	width: 200px;
	height: auto;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 3.2rem;
	right: 0.5rem;
	z-index: 99999999999;
	padding: 12px 8%;
	gap: 12px;
	box-shadow: -2px 4px 10px 4px #c4c4c470;
	background: #fefefe;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	@media (max-width: 880px) {
		top: 2.9rem;
		right: 0.5rem;
	}
	@media (max-width: 600px) {
		top: 2rem;
		right: 0.5rem;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 7rem;
		top: -6px;
		width: 10px;
		height: 10px;
		background: #ffffff;
		border-right: 1px solid #cad5e0;
		border-bottom: 1px solid #cad5e0;
		transform: rotate(-135deg);
		z-index: 999;
		@media (max-width: 880px) {
			right: 1.5rem;
		}
		@media (max-width: 600px) {
			right: 1.3rem;
		}
		@media (max-width: 500px) {
			right: 0.2rem;
		}
	}
`

const Box = styled.div`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
`

const LynitLogo = styled.img`
	height: 22px;
	cursor: pointer;
`

const ViewLabel = styled.p`
	margin: 0;
	font-size: 13px;
	text-align: center;
	font-weight: 500;
	text-decoration:${props => (props.isActive ? `underline` :  `unset`)};
`

const ViewContainer = styled.div`
	width: auto;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	filter: ${props => (props.isActive ? 'drop-shadow(0px 0px 10px rgba(255, 195, 0, 1))' : 'none')};
	align-items: center;
	height: 40px;
    justify-content: space-between;
`

const OutlliningLogo = styled.img`
	height: 18px;
	cursor: pointer;
`
const WritingLogo = styled.img`
	height: 18px;
	cursor: pointer;
`

const ProfileContainer = styled.div`
	width: auto;
	margin-right: 5px;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	user-select: none;
	@media (max-width: 880px) {
		width: auto;
		min-width: auto;
	}
	img {
		width: 0.8rem;
		@media (max-width: 1024px) {
			padding: none;
		}
		@media (max-width: 500px) {
			display: none;
		}
	}
	p {
		margin: 0;
		color: #555555;
		@media (max-width: 1024px) {
			padding: none;
		}
		@media (max-width: 880px) {
			display: none;
		}
	}
	cursor: pointer;
`

const HelperDiv = styled.div`
	width: auto;
	height: auto;
	display: flex;
	max-width: 40rem;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-left: 1rem;
	@media (max-width: 600px) {
		display: none;
		min-width: auto;
		max-width: 20rem;
	}
`

export {
	Box,
	Container,
	LynitLogo,
	NameButton,
	HelperDiv,
	MenuContent,
	LogoutButton,
	MenuContainer,
	ProfileOptions,
	ProfileContainer,
	HeaderLeftContainer,
	FrameOption,
	OutlliningLogo,
	WritingLogo,
	ViewLabel,
	ViewContainer
}
