
import styled from "styled-components";
import whitePlusIconYellowBackground from '@lynit/shared/src/images/whitePlusIconYellowBackground.svg'
import { getNodeIcon, vizTitleLengthFormat } from "@lynit/shared/src/utils/utils";
import { systemStateContext } from "@lynit/shared/src/state/systemProvider";
import { useContext } from "react";





const MissingPremiseDriver =  ({text, handleClick, connectionModalType}) => {
  const shortname = vizTitleLengthFormat(text)
  const { createLog } = useContext(systemStateContext)
  
  return (

        <Div 
          onClick={e => {
            handleClick(e, connectionModalType)

        }}
          onMouseEnter={() => {


            createLog(
              `Missing Premise Driver Hovered `,
              `{"elementType":"${connectionModalType.type}"}`,
              'Missing Premise Driver',
              null,
            )
          } }
        >
            <Div2>
                <div className="icon-container">
							    <img src={getNodeIcon(connectionModalType.type)} />
						    </div>
                {/* `Support or Oppose "${shortname}"` */}
               
                <Div3>{`Support or Oppose "${shortname}"`}</Div3>

                <img
                    src={whitePlusIconYellowBackground}
                />
                
            </Div2>
        </Div>

    
  );
}

export default MissingPremiseDriver

const Div = styled.div`
  border-radius: 4px;
  border: 1px dashed #ffc300;
  background-color: #fff;
  position: relative;
  //display: flex;
  width: 82%;
  //align-items: start;
  //justify-content: space-between;
  //gap: 20px;
  cursor: pointer;
  padding: 3px 5px;
  left: 37px;
  :hover {
		background-color: #FFC300;
		border-color: transparent;
	}
  
 
`;

const Div2 = styled.div`
  display: flex;
  gap: 7px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const Div3 = styled.div`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  align-self: center;
  flex-grow: 1;
  white-space: wrap;
  margin: auto 0;
  font: 600 12px Source Sans Pro, sans-serif;
  
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 14px;
  overflow: hidden;
  margin-top: 15px;
  max-width: 100%;
`;

