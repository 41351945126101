import { useEffect, useState, useContext } from 'react'
import { Collapse, List } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowRight'

import { CustomListItem as ListItem, IconContainer, SubList, AddElementContainer } from './styles'
import { getNodeIcon, throttle, createNodeId, nodeTypeForId } from '../../utils/utils'
import tooltipIcon from '../../images/tool-tip.svg'
import addIcon from '../../images/add-driver-icon.svg'
import SuggestionTooltip from '../ToolTip/SuggestionTooltip'
import { useApolloClient } from '@apollo/client'
import { createNodeMutations } from '../../data'
import { sharedContext } from '../../state'
import { setNewConnId } from '../../state/actions'
import { createCacheElement, createCacheConnection } from '../../utils/apollo'
import { systemStateContext } from '../../state/systemProvider'
import { useCreateNodeMutation } from '../../hooks'
import { useCreateConnectionAndElementMutation } from '../../hooks/createConnectionAndElement'

const elementTypes = ['Character', 'Event', 'TSM', 'Arc']

const SingleLevel = ({
	item: { title, type, node, isSupporting },
	connectionType = '',
	onClick: handleClick,
}) => {
	const {createLog}= useContext(systemStateContext)
	const structureTag = elementTypes.includes(connectionType)
		? isSupporting
			? `Supporting`
			: `Opposing`
		: connectionType

	return (
		<ListItem
			button
			onClick={() => {
				handleClick(node?.id, structureTag)
				createLog(
					`Connection Creation Element Selected`,
					`{"elementType": "${type}", "connectionType":"Static"}`,
					`Single Level Element`,
					'Connection Creation',
				)
			}}
			iselement={String(!!node?.id)}
			type={type}
		>
			<div>
				<IconContainer type={type} 
				iselement={!!node?.id}
				>
					<img src={getNodeIcon(type)} />
				</IconContainer>
				<span>{title}</span>
			</div>
		</ListItem>
	)
}

const MultiLevel = ({
	item: { items: children, title, type, isTitle, tooltip, isSupporting, structureTagIcon },
	...rest
}) => {

	const [isExpanded, setIsExpanded] = useState(isTitle)
	const [isEdit, setIsEdit] = useState(false)
	const [elementName, setElementName] = useState('')

	const [performCreateNodeMutation] = useCreateNodeMutation(type||"Character",{ignoreResults:true})
	
	const { createLog }= useContext(systemStateContext)
	//const isSupporting = connectionModalType?.type ==="Supporting"
	const {
		dispatch,
		//state: { refetchUser }  ,
	} = useContext(sharedContext)
	
	const client = useApolloClient()
	const {onClick:handleCreateConnection, mainElementId, onClose:handleClose,connectionModalType, handleCreateConnectionAndElement } = rest
	const isRecommendedDriver = ! ["Supporting","Opposing"].includes(connectionModalType.type)

	
	const handleExpandtion = () => {
		
		if (!isTitle && !isRecommendedDriver) {
			setIsExpanded(prev => !prev)
		} else if (isRecommendedDriver && !isTitle ){
			handleCreateConnection(connectionModalType.recommendedDriverId,title)
		}
		
	}

	const handleCreateElement = async () => {
			const newNodeId = createNodeId(type)
		const tempObj = {
			id: newNodeId,
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: '0',
			__typename: type,
			name: elementName,
			description: '',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			firstBeat: '',
			coreElement: "false",
			storyText: "",

		}
		const optimisticResponse = {}
		optimisticResponse[`create${type}`] = tempObj
		


		const structureTag = elementTypes.includes(type)
		? isSupporting
			? `Supporting`
			: `Opposing`
		: type

		const tempId = createNodeId("Relationship")

		const newConnection = {
			id: tempId,
			description: '',
			arcStage: null,
			relName: 'RELATED',
			order: null,
			connectionType: 'Static',
			structureTag,
			arc: null,
			sourceNode: {
				id: mainElementId,
				__typename: nodeTypeForId(mainElementId),
			},
			destNode: {
				id: newNodeId,
				__typename: nodeTypeForId(newNodeId),
			},
			__typename: 'Relationship',
			beatsDriven:null,
			sharedBeats:[],
			firstBeat: '',
			createdAt: new Date().toISOString()
		}
		dispatch(setNewConnId(tempId))
		handleClose()

		setIsEdit(false)
		
		handleCreateConnectionAndElement(newConnection,tempObj,optimisticResponse)

		// const res = await createNode({
		// 	variables: {
		// 		input: {
		// 			id: tempObj.id,
		// 			name: elementName,
		// 			description: '',
		// 			order: '0',
		// 		},
		// 	},
		// 	ignoreResults: true
		// })
		createLog(
			`Connection Creation Element Selected`,
			`{"connectionType":"Static"}`,
			`Fast Create`,
			'Connection Creation',
		)
		// handleCreateConnection(newNodeId, structureTag, newConnection)
		
	}

	if(isRecommendedDriver && isTitle){
		type = connectionModalType.type
	} else if(!isTitle && isRecommendedDriver){
		type = structureTagIcon

	}
	return (
		<>
			<ListItem button onClick={handleExpandtion}>
				<div>
					<IconContainer type={isTitle && !isRecommendedDriver ? title : type} isTitle={(isTitle && !isRecommendedDriver) || structureTagIcon} iselement={false}>
						<img src={getNodeIcon(isTitle && !isRecommendedDriver ? title : type)} />
					</IconContainer>
					<span>{title}</span>
				</div>
				<div>
					{isRecommendedDriver? <></> : isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
					{tooltip ? (
						<SuggestionTooltip
							title={tooltip}
							placement="right"
							arrow
							onOpen={() =>
								createLog(
									`Suggestion Tooltip hovered`,
									`{"Menu Item": "${title}"}`,
									`MenuItem`,
									'Create Connection Menu Item',
								)
							}
						>
							<img src={tooltipIcon} alt="Tool-tip" />
						</SuggestionTooltip>
					) : null}
				</div>
			</ListItem>
			<Collapse in={isExpanded} timeout="auto" unmountOnExit>
				<SubList>
					<List component="div" disablePadding>
						{children.map((child, index) => (
							<MenuItem
								key={index}
								{...rest}
								item={{ isSupporting, ...child }}
								connectionType={title}
							/>
						))}
						{!isTitle && !isRecommendedDriver && (
							<AddElementContainer type={type} isEdit={isEdit}>
								<IconContainer type={type} isElement={true} onClick={() => setIsEdit(true)}>
									<img src={addIcon} alt="new-Character-icon" />
								</IconContainer>
								{!isEdit ? (
									<span onClick={() => setIsEdit(true)}>Fast Create New {type}</span>
								) : (
									<div className="input-container" type={type}>
										<input
											autoFocus
											type="text"
											placeholder="Name"
											onChange={e => {
												setElementName(e.target.value)
											}}
										/>

										{elementName?.length ? (
											<button onClick={() => throttle(handleCreateElement)}>Create</button>
										) : null}
									</div>
								)}
							</AddElementContainer>
						)}
					</List>
				</SubList>
			</Collapse>
		</>
	)
}

const MenuItem = ({ item, ...rest }) => {
	const isMultiLevel = item.items?.length || !item.node?.id
	const Component = isMultiLevel ? MultiLevel : SingleLevel
	return <Component item={item} {...rest} />
}

export default MenuItem
