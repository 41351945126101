import styled from 'styled-components'
import { colorStyles, nodeColors } from '@lynit/shared/src/utils/commonStyles'
import diveDeepHover from '@lynit/shared/src/images/diveDeepHover.svg'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const ElementContainer = styled.div`
	box-sizing: border-box;
	overflow-x: hidden;
	width: auto;
	height: ${({ isCollapsed }) => (isCollapsed ? 'auto' : '100%')};
	display: flex;
	flex-direction: column;
	margin-bottom: 0.8rem;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: ${props => (props.isSelected ? 'white' : 'white')};
	border-radius: 4px;
	padding: 0.7rem;
	z-index: 0;
	user-select: none;

	:hover {
		background-color: ${props => props.isCollapsed && '#dbdbdb'};
	}
	opacity: ${props => (props.isDragging ? '0.5' : 'unset')};
	@media (max-width: 400px) {
		padding: 0.7rem 0.5rem;
	}
`

const CollapsedElement = styled.div`
	max-width: 100%;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	white-space: nowrap;
	p {
		font-weight: bold;
	}
`

const ArrowIcon = styled.img`
	width: 0.9rem;
	height: 0.9rem;
	transform: ${props => (props.isCollapsed ? 0 : 'rotate(90deg)')};
	cursor: pointer;
	padding: 5px;

	:hover {
		background: ${props => props.isCollapsed && '#c2c2c2'};
		border-radius: 3px;
	}
`

const ArrowIconConnectionCard = styled.img`
	width: 0.5rem;
	height: 0.5rem;
	left: 50%;
	top:  ${props => (props.isCollapsed  ? "5%" : '14%')}; 
	transform:  ${props => (props.isCollapsed  ? "rotate(90deg)" : 'rotate(270deg)')}; 
	cursor: pointer;
	position: absolute;
	//padding: 5px;
	//height: 60%;
	display: ${props => (props.collaspedCardHovered ? "block" : 'none')};
	//display: block;
	
`

const UncollapsedElement = styled.div`
	padding: 8px;
	width: 100%;
	height: calc(100% - 20px);
	overflow-x: hidden;
	display: ${props => (props.isCollapsed ? 'none' : 'flex')};
	flex-direction: column;
	background-color: white;
	justify-content: flex-start;
	transition: 3ms;
	.beats-heading {
		margin: 0;
		font-weight: 600;
	}
	@media (max-width: 500px) {
		font-size: 0.8rem;
		padding: 4px;
	}

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar {
		width: 4px;
	}
`

const BottomSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #888585;
	font-family: Source Sans Pro;
	font-style: normal;
	line-height: normal;
	margin-right: 5px;
	@media (max-width: 500px) {
		font-size: 0.6rem;
	}
	div {
		width: auto;
		height: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 0;
		font-size: 13px;
		cursor: pointer;
		@media (max-width: 500px) {
			font-size: 0.6rem;
		}
		p {
			color: ${({ isHover, isDeepMode }) => (isHover || isDeepMode ? '#FFC300' : '#888585')};
			font-weight: 600;
			margin: 0;
			margin-left: 0.4rem;
		}
		@media (max-width: 400px) {
		img {
			height: 13px;
			width: 13px;
		}
		p {
			display: none;
		}
		}
	}
`

const UpdateButton = styled.button`
	width: 4rem;
	height: 1.7rem;
	border-radius: 3px;
	border: none;
	background-color: ${colorStyles.lynitYellow};
	cursor: ${props => (props.disabled ? '' : 'pointer')};
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	&:hover {
		text-decoration: none;
		transform: ${props => (props.disabled ? 'translateY(0)' : 'translateY(-2px)')};
	}
	&:active {
		transform: translateY(0);
		background-color: ${props => (props.disabled ? 'white' : `${colorStyles.lightGrey}`)};
		transition: linear 400ms;
	}
	@media (max-width: 500px) {
		width: 2.5rem;
		height: 1.3rem;
		font-size: 0.6rem;
	}
`

const CollapsedContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		margin: 0;
	}
	:hover {
		color: ${props => props.hoverStyle && !props.isCollapsed && '#224c96'};
		cursor: ${props =>
			props.hoverStyle && !props.isCollapsed ? `url(${diveDeepHover}), auto` : 'pointer'};
		text-decoration: ${props => props.hoverStyle && !props.isCollapsed && 'underline'};
	}
`

const HelperButtons = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 0;
	cursor: pointer;
`

const IconContainer = styled.div`
	width: auto;
	height: auto;
	padding: 0.4rem 0.7rem 0.4rem 0.7rem;
	border: 1px solid #2d81ff;
	border-radius: 20px;
	background: #fefefe;
	font-size: 0.9rem;
	color: #2d81ff;
	display: flex;
	@media (max-width: 999px) {
		padding: 0.4rem 0.4rem 0.4rem 0.7rem;
	}
	@media (max-width: 768px) {
		font-size: 11px;
		img {
			width: 11px;
		}
	}
	img {
		margin-right: 0.5rem;
	}
`

const DeleteIcon = styled.img`
	display: block;
	cursor: pointer;
	opacity: 0.7;
	@media (max-width: 500px) {
		font-size: 0.6rem;
	}
`

const DiveDeepButton = styled.button`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	white-space: nowrap;
	text-align: center;
	border-radius: 20px;
	border: ${({ isDeepMode }) => (isDeepMode ? '1px solid #B2CEE9' : `1px solid #2D81FF`)};
	background-color: ${props => (props.isDeepMode ? '#B2CEE9' : '#FEFEFE')};
	color: black;
	color: ${({ isDeepMode }) => (isDeepMode ? '#0d1b4e' : '#2D81FF')};
	cursor: pointer;
	padding: 0.4rem 0.7rem 0.4rem 0.7rem;

	@media (max-width: 768px) {
		font-size: 11px;
		img {
			width: 11px;
		}
	}
	img {
		margin-right: 0.3rem;
	}
	padding-right: 0.9rem;
`

const TextFieldsContainer = styled.div`
	display: flex;
	font-weight: bold;
`

const DraggerContainer = styled.div`
	height: 15px;
	display: flex;
	gap: 10px;
	@media (min-width: 601px) and (max-width: 768px) {
		height: 13px;
		gap: 5px;
	}
`

const IconsContainer = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	align-items: center;
	@media (max-width: 768px) {
		gap: 5px;
	}
	@media (max-width: 360px) {
		width: 100%;
	}
`

const Element = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
`

const BeatsContainer = styled.div`
	width: auto;
	display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
	flex-direction: column;
	background: #d7edde;
	border: ${({ isEmpty }) => (isEmpty ? '2px dashed #78C091' : 'unset')};
	border-radius: 10px;
	box-shadow: ${({ isEmpty }) =>
		isEmpty ? 'unset' : '0px 0.888587px 0.888587px rgba(9, 30, 66, 0.1)'};
	user-select: none;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const CreateBeatQuill = styled.div`
	min-height: 25px;
	height: auto;
	border: 0.5px solid #f2f2f2;
	border-radius: 10px;
	background-color: #fbfbfb;
	box-shadow: 0px 0.888587px 0.888587px rgba(9, 30, 66, 0.1);
	.ql-editor {
		padding: 8px 10px;
		::before,
		p {
			margin: 0;
			font-size: 12px;
			font-family: 'Source Sans Pro';
			line-height: 15px;
			vertical-align: middle;
		}
		::before {
			left: 10px;
			right: 10px;
		}
	}
`

const AddBeat = styled.button`
	//width: 60px;
	padding: 2px 5px;
	margin-top: 5px;
	background-color: #78c091;
	color: #ffffff;
	border-radius: 2px;
	border: none;
	font-size: 9px;
	font-weight: 600;
	white-space: nowrap;
	cursor: pointer;
`

const ElementWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	width: 100%;
	max-width: calc(100% - 25px);
	@media (max-width: 320px) {
		flex-wrap: wrap;
	}
	cursor: pointer;
`

const BeatsTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	.title {
		font-size: 12px;
		font-weight: bold;
		margin: 10px 0;
	}
`

const CreateBeat = styled.button`
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	font-family: 'Source Sans Pro';
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	color: #78c091;
`

const TabsContainer = styled.div`
	background-color: #f5f5f5;
	border-radius: 4px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
`
const CountIcon = styled.div`
	border-radius: 50%;
	background-color: #888585;
	color: #fff;
	font-family: Nunito Sans;
	font-size: 11px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	height: 15px;
	width: 15px;
	//left: -15px
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin: 0 !important;
	}
`

const TabContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 0px 5.5px;
	color: ${({ isActive }) => (isActive ? '#000000' : '#888585')};
	img {
		height: 24px;
		opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
	}
	p,
	div {
		margin-left: 5px;
	}
	div {
		background-color: ${({ isActive }) => (isActive ? '#000000' : '#888585')};
	}
	@media (max-width: 992px) {
		flex-wrap: wrap;
		justify-content: center;
		font-size: 11px;
		img {
			order: 0;
			height: 22px;
		}
		div {
			order: 1;
			height: 17px;
			width: 17px;
			font-size: 9.5px;
		}
		p {
			order: 2;
			margin: 10px 0px 0px;
			flex: 100%;
		}
	}
	@media (max-width: 400px) {
	padding: 0px;
		img {
			height: 18px;
		}
		div {
			height: 15px;
			width: 15px;
			font-size: 9.5px;
		}
	}
`

const BeatsExpanded = styled.div`
	display: flex;
	align-items: center;
	div {
		height: 18px;
		width: 18px;
		p {
			font-size: 11px;
		}
	}
	p {
		margin: 0 0 0 10px;
		font-size: 12px;
	}
	background-color: #fff3ce;
	padding: 6px 10px;
	border-radius: 4px;
	margin-bottom: 10px;
`
const IndividualConnectionWrapper = styled.div`
	position: relative;
	margin: 20px 0;
	height: 100%;
	::after {
		position: absolute;
		content: '';
		height: ${({ firstCard }) => (firstCard ? 'calc(100% + 20px)' : 'calc(100% + 20px)')};
		border-left: 1px solid #3c40439e;
		//top: ${({ firstCard }) => (firstCard ? '21%' : '-15%')};
		//top: 21%;
		transform: translateY(-10px);
		width: 0;
		left: 4px;
	}

	::before {
		position: absolute;
		content: '';
		width: 40px;
		top: 40px;
		border-top: 1px solid #3c40439e;
		left: 4px;
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		width: 100%;
		::before {
			width: 30px;
		}
	}
	@media (max-width: 400px) {
		::before {
			width: 25px;
	}
`

// const driverCardStyle = {
// 	firstcard: {height : }
// }

// top: ${({ firstCard, prevConnCard }) => {
// 	if(firstCard & prevConnCard){
// 		return '-120%;'
// 	} else if(firstCard){
// 		return '-10%;'
// 	} else {
// 		return '-35%;'
// 	}
// 	}}



const NewIndividualConnectionWrapper = styled.div`
	position: relative;
	margin: 20px 0;
	height: 100%;
	.icon-container {
		display: flex;
		width: 1rem;
		height: 1rem;
		justify-content: center;
		align-items: center;
		border: 1px solid ${props => getColor(props.type)};
		border-radius: 50%;
		object-fit: contain;
		background: ${props => getColor(props.type)};
		//margin-bottom: 10px;
		img {
			width: 10px;
			height: auto;
		}
	}
	::after {
		position: absolute;
		content: '';
		height: ${({ firstCard, prevConnCard , beatsDriven}) => {
			if(firstCard & prevConnCard){
				return 'calc(100%);'
			} else if(firstCard){
				return 'calc(100% + 20px);'
			} else {
				return `calc(100% + ${ beatsDriven > 0 ? '20px' : '18px'}  );`
			}
			}}
		//height: ${({ firstCard, prevConnCard }) => (prevConnCard && firstCard ? 'calc(100% )' : 'calc(150% )')};
		border-left: 1px ${({beatsDriven})=> (beatsDriven > 0 ? 'solid' : 'dashed')} ${({isMissingDriver}) => (isMissingDriver ? '#ffc300' : '#000' )};
		
		//top: ${({ firstCard }) => (firstCard ? '-10%' : '-35%')};
		transform: translateY( ${({firstCard, prevConnCard, isCollapsed}) => {
			if(isCollapsed && firstCard ){
				return '-41px'
			} else if(firstCard){
				return '-100%'
			} else {
				return '-100%'
			}
		}});
		width: 0;
		left: 4px;
		
	}

	::before {
		position: absolute;
		content: '';
		width: 75px;
		top: 12px;
		border-top: 1px ${({beatsDriven})=> (beatsDriven > 0 ? 'solid' : 'dashed')} ${({isMissingDriver}) => (isMissingDriver ? '#ffc300' : '#000' )};
		left: 4px;
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		width: 100%;
		::before {
			//width: 30px;
		}
	}
	@media (max-width: 400px) {
		::before {
			//width: 25px;
	}
`

const ConnectionIconContainer = styled.div`
	position: absolute;
	top: 3px;
	left: -26px;
	img {
		height: 18px;
		width: 18px;
		cursor: pointer;
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		// left: -27px;
		//top: 18px;
		img {
			height: 16px;
			width: 16px;
		}
	}
	@media (max-width: 400px) {
		// left: 8px;
		// top: 32px;
		img {
			height: 14px;
			width: 14px;
		}
	}
`
const ConnectionInnerWrapper = styled.div`
	position: relative;
	left: 75px;
	height: auto;
	width: 75%;
	display: inline-block;
`

const Story = styled.div`
	background: #ffffff;
	border: 2px solid #b5b5b5;
	border-radius: 4px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 36px;
	width: 100%;
	height: 36px;
	position: absolute;
	left: -36px;
	top: 16px;
	z-index: 1;
	.icon-container {
		display: flex;
		width: 1.5rem;
		height: 1.5rem;
		justify-content: center;
		align-items: center;
		border: 1px solid ${props => getColor(props.type)};
		border-radius: 50%;
		object-fit: contain;
		background: ${props => getColor(props.type)};
		margin-bottom: 10px;
		img {
			width: 12px;
			height: auto;
		}
	}

	.element-type {
		font-size: 9px;
		color: #222222;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 42px;
		position: absolute;
		bottom: 2px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
		white-space: nowrap;
	}

	@media screen and (min-width: 600px) and (max-width: 914px) {
		::after {
			height: 95px;
		}
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		left: -42px;
		height: 30px;
		max-width: 30px;
		.icon-container {
			width: 1.2rem;
			height: 1.2rem;
			img {
				width: 10px;
			}
		}
		.element-type {
			font-size: 8.8px;
		}
	}
	@media (max-width: 400px) {
		left: -49px;
		height: 30px;
		max-width: 27px;
		.icon-container {
			width: 1rem;
			height: 1rem;
		}
	}
`

const ConnectionDescription = styled.div`
	border: 2px solid #b5b5b5;
	border-radius: 4px;
	position: relative;
	transform: translateY(0%);
	margin: 0 auto;
	//margin-bottom:5px;
	top:2px;
	left: -1px;
	height: auto;
	width: calc(100% - 2px);
	background-color: white;
	display: inline-block;
	svg {
		position: absolute;
		z-index: -1;
		border-radius: 4px;
		top: -1%;
	}
	.ql-container {
		background-color: transparent;
		position: relative;
		word-break: break-word;
		box-sizing: border-box;
		-moz-text-overflow: ellipsis;
		width: 95%;
		font-size: 11px;
		display: inline-block;
		margin: 0 5px;
	}
	.ql-editor{
		padding: 6px 5px;
	}
	@media (min-width: 950px) and (max-width: 1024px) {
		//left: -25px;
		.ql-container {
			//width: 70%;
			font-size: 10px;
			//left: 10px;
		}
	}
	@media (min-width: 401px) and (max-width: 949px) {
		//left: -25px;
		.ql-editor {
			//padding: 6px 5px;
			line-height: 1;
		}
		.ql-container {
			//left: 10px;
			font-size: 10px;
			//width: 63%;
		}
	}
	@media (max-width: 400px) {
		//left: -32px;
		.ql-editor {
			//padding: 6px 15px;
			line-height: 1;
		}
		.ql-container {
			//left: 10px;
			font-size: 10px;
			//width: 58%;
		}
	}
`

const RightDescription = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: end;
	height: 100%;
	justify-content: space-between;
	position: absolute;
	right: 0px;
	top: 0px;
	img {
		height: 12px;
		margin: 5px;
	}
`
const BeatDrivenCount = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: end;
	div {
		height: 17px;
		width: 17px;
		font-size: 10px;
		margin-right: 5px;
		background-color: #f5f5f5;
		color: #676767;
	}
	img {
		height: 16px;
		width: 22x !important;
		opacity: 0.7;
	}
	@media (max-width: 400px) {
		div {
			height: 15px;
			width: 15px;
		}
		img {
		height: 14px;
		}
	}
`
const ConnectionWrapper = styled.div``

const Line = styled.div`
	height: 12px;
	width: 12px;
	border-radius: 50%;
	background-color: #b5b5b5;
	position: absolute;
	top: 40px;
	z-index: 1;
`
const CreateConnection = styled.div`
	border-radius: 4px;
	background-color: #f8f8f8;
	color: #222222;
	font-size: 11px;
	display: flex;
	cursor: pointer;
	padding: 8px;
	position: relative;
	user-select: none;
	flex-direction: column;
	width: 65%;
	left: 45px;
	top: 30px;

	p {
		margin: 0;
	}

	.title {
		font-weight: bold;
		font-size: 12px;
		text-decoration: underline;
		margin-bottom: 5px;
	}

	.description {
		font-size: 10px;
		margin-bottom: 20px;
	}

	${({noCards,isLength})=> {
		return noCards ? `::after {
			position: absolute;
			content: '';
			left: -30px;
			border-left: 1px ${isLength ? 'dashed' : 'dashed' } #3c40439e;
			width: 0;
			height: calc(50% + ${ (isLength ? '8px' : '-5px')});
			top: ${(isLength ? '-8px' : '1px')};
		}
	
		::before {
			position: absolute;
			content: '';
			width: 30px;
			top: 40px;
			border-top: 1px dashed #3c40439e;
			left: -30px;
		}
		// @media (min-width: 401px) and (max-width: 1024px) {
		// 	width: 100%;
		// 	::before {
		// 		width: 30px;
		// 	}
		// }
		@media (max-width: 400px) {
			::before {
				width: 25px;
		}` : '' 
	}}
	
	
	
`

const PlusIcon = styled.div`
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: black;
	left: -25px;
	top: 47%;
	transform: translateY(-50%);
	color: #fff;
	font-size: 24px;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
`

const LeftDashLine = styled.div`
	position: absolute;
	left: -56px;
	width: 35px;
	top: 47%;
	border-bottom: 1px ${({ isLength }) => (isLength ? 'solid' : 'dashed')} #3c40439e;
`

const CreateConnectionBtn = styled.div`
	box-sizing: border-box;
	cursor: pointer;
	width: 100%;
	height: 100%;
	padding: 5px 10px;
	border-radius: 20px;
	border: 1px solid ${({ isSupporting }) => (isSupporting ? '#4dbb5e' : '#D00')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;

	:hover {
		background-color: ${({ isSupporting }) => (isSupporting ? '#E7F0E9' : '#F6E7E7')};
		border-color: transparent;
	}
	@media (min-width: 320px) and (max-width: 1024px) {
		.icon {
			padding-right: 10px;
		}
		.tool-tip {
			padding-left: 10px;
		}
	}
`
const DescriptionSeparator = styled.div`
	background-color: #e9e9e9;
	height: 100%;
	width: ${({ width }) => `${width}px`};
	position: absolute;
	right: 50px;
	top: 0;
`

const DriverSection = styled.div`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  margin-top: 12px;
  width: 75%;
  
  font: 700 12px Source Sans Pro, sans-serif;
`;

export {
	ElementContainer,
	CollapsedElement,
	UncollapsedElement,
	ArrowIcon,
	ArrowIconConnectionCard,
	BottomSection,
	UpdateButton,
	CollapsedContainer,
	HelperButtons,
	DeleteIcon,
	DiveDeepButton,
	IconContainer,
	TextFieldsContainer,
	DraggerContainer,
	IconsContainer,
	Element,
	BeatsContainer,
	CreateBeatQuill,
	AddBeat,
	ElementWrapper,
	BeatsTitle,
	CreateBeat,
	TabsContainer,
	CountIcon,
	TabContainer,
	BeatsExpanded,
	ConnectionDescription,
	DescriptionSeparator,
	RightDescription,
	BeatDrivenCount,
	ConnectionWrapper,
	Story,
	IndividualConnectionWrapper,
	NewIndividualConnectionWrapper,
	Line,
	ConnectionIconContainer,
	CreateConnectionBtn,
	PlusIcon,
	CreateConnection,
	LeftDashLine,
	ConnectionInnerWrapper,
	DriverSection,
}
