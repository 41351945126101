import Quill from 'quill'
import { Fonts, Sizes } from '../../utils/utils'



const Delta = Quill.import('delta')
const Font = Quill.import('formats/font')
const Clipboard = Quill.import('modules/clipboard')

class PlainTextClipboard extends Clipboard {
	onPaste(e) {
		if (e.defaultPrevented || !this.quill.isEnabled()) return
		const range = this.quill.getSelection()
		let delta = new Delta().retain(range?.index)
		let pasteMatch = false
		this.addMatcher(Node.ELEMENT_NODE, function (node, copiedDelta) {
			const fontClassName = node.className?.split(" ")?.find(className => (className).includes("ql-font-"))?.replaceAll("ql-font-",'')
			const matchingClassNameFont = Fonts.find(font => font ===fontClassName )
			let font 
			if(matchingClassNameFont){
				font = matchingClassNameFont
			} else if (node.style.fontFamily ) {

				const nodeFonts = node.style?.fontFamily
				?.split(',')
				.map(f => f.trim().replaceAll(`"`, '').replaceAll(' ', '-'))
				
				font = nodeFonts.find(nf => {
					return Fonts.find(font => font === nf) 
				})

			} else {

			return copiedDelta

			}
			
			let newDelta = new Delta()
			copiedDelta.ops.forEach(op => {
				
				newDelta.insert(op.insert, {font,
					...(op.attributes ?? {}),
					
				})
			})
			return newDelta
			
		})
		if (e && e.clipboardData && e.clipboardData.types && e.clipboardData.getData) {
			
			let html = e.clipboardData.getData('text/html')
			if (!html) {
				pasteMatch = true
				html = e.clipboardData.getData('text/plain')
				this.quill.insertText(range?.index, html)
			}
			const htmlToDelta = this.convert(html)
			// Stop the data from actually being pasted
			e.stopPropagation()
			e.preventDefault()

			delta = delta.concat(htmlToDelta).delete(range.length)

			// Removed indent at the starting of the line
			// const regEx = /^[\t]/
			// if (Array.isArray(delta?.ops)) {
			// 	delta.ops = delta.ops.map(item => {
			// 		if (item?.insert) {
			// 			return {...item, insert: item.insert.replace(regEx, '')}
			// 		}
			// 		return item
			// 	})
			// }
			if (!pasteMatch) {
				this.quill.updateContents(delta, Quill.sources.USER)
			}

			//scroll to visible the cursor
			const selection = window.getSelection()
			const firstRange = selection.getRangeAt(0)
			const tempAnchorEl = document.createElement('br')
			firstRange.insertNode(tempAnchorEl)
			tempAnchorEl.scrollIntoView({
				block: 'nearest',
			})
			tempAnchorEl.remove()

			this.quill.setContents(this.quill.getContents())
			this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT)
			return false
		}
	}
}

Quill.register('modules/clipboard', PlainTextClipboard,true)

Font.whitelist = Fonts
Quill.register(Font, true)

const Size = Quill.import('attributors/style/size')

Size.whitelist = [...Sizes.map(size => `${Math.round(size / 0.75)}px`)]

Quill.register(Size, true)

const Parchment = Quill.import('parchment')

const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const TAB_MULTIPLIER = 30

// eslint-disable-next-line
class IndentAttributor extends Parchment.Attributor.Style {
	add(node, value) {
		if (value === '+1' || value === '-1') {
			const indent = this.value(node) || 0
			value = value === '+1' ? indent + 1 : indent - 1
		}
		if (value === 0) {
			this.remove(node)
			return true
		}

		return super.add(node, `${+value * TAB_MULTIPLIER}px`)
	}

	canAdd(node, value) {
		if (node.nodeName === 'LI' && this.keyName === 'text-indent') {
			this.keyName = 'margin-left'
		} else if (this.keyName === 'margin-left' && !(node.nodeName === 'LI')) {
			this.keyName = 'text-indent'
		}
		return super.canAdd(node, value) || super.canAdd(node, parseInt(value, 10))
	}

	value(node) {
		return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined // Don't return NaN
	}
}

const IndentStyle = new IndentAttributor('indent', 'text-indent', {
	scope: Parchment.Scope.BLOCK,
	whitelist: pixelLevels.map(value => `${value * TAB_MULTIPLIER}px`),
})

Quill.register(IndentStyle, true)


export default Quill