/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { FullScreen } from 'react-full-screen'
import Tooltip from '@mui/material/Tooltip'
import { colorStyles, nodeColors } from '@lynit/shared/src/utils/commonStyles'

const StyledFullscreen = styled(FullScreen)`
	height: ${props => (props.isFullscreen ? '100vh !important' : '100%')};
`

const VizContainer = styled.div`
	width: 100%;
	height: ${props => (props.isFullscreen ? '100vh' : '100%')};
	display: flex;
	flex-direction: column;
	position: relative;
	@media (max-width: 500px) {
		width: 100%;
		display: flex;
		height: 100%;
	}
`

const Bar = styled.div`
	overflow-y: hidden;
	box-sizing: border-box;
	width: 100%;
	height: ${props => (props.isBottom ? '13px' : '2rem')};
	max-height: 2rem;
	background-color: #f8f8f8;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 1rem;
	border-radius: ${props => (props.isBottom ? '0 0 5px 5px' : '5px 5px 0 0')};
	z-index: 0;

	@media (min-width: 1025px) and (max-width: 1400px) {
		font-size: 11px;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 9px;
		padding-left: 5px;
	}
	@media (max-width: 500px) {
		display: flex;
		padding-left: ${props => (props.isBottom ? '2px' : '')};
	}
	span {
		color: #000000;
		font-weight: 400;
	}
	.fullscreen-icon {
		cursor: pointer;
		padding-right: 0.3rem;
	}
	p {
		width: 95%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	img {
		margin-right: 10px;
	}
	p {
		width: 95%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	p {
		width: 95%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const Btnwrap = styled.div`
	display: none;
	height: auto;
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 0;
	z-index: 1;
	button {
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s ease;
		margin: 5px 0;
	}

	button {
		opacity: 1;
		visibility: visible;
	}
`

const Button = styled.button`
	white-space: nowrap;
	border-radius: 4px;
	max-width: 140px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	width: 100%;
	height: 24px;
	line-height: 24px;
	border: none;
	background: ${props => (!props.isDeepMode ? '#CACACA' : `${colorStyles.lynitYellow}`)};
	cursor: pointer;
	color: ${props => (!props.isDeepMode ? 'white' : 'black')};
	@media (max-width: 1400px) {
		font-size: 11px;
	}
	@media (max-width: 1024px) {
		font-size: 9px;
	}
	@media (max-width: 374px) {
		max-width: 100px;
		font-size: 8px;
		padding: 0;
	}
`

const VizContent = styled.svg`
	height: 100%;
	width: 100%;
	border-radius: 5px 5px 0 0;
	background: #f3f3f6 !important;
`

const DeepModeContainer = styled.div`
	width: 100%;
	overflow: hidden;
	height: 100%;
	max-height: ${({ isFullscreen }) => (isFullscreen ? '100vh' : '100%')};
	background: white;
`

const ConnectionListContainer = styled.div`
	position: absolute;
	margin-top: 20px;
	margin-left: 50px;
`

const ConnectionListTitle = styled.div`
	display: flex;
	align-items: center;
	width: 105px;
	height: 20px;
	gap: 3px;
	border-radius: 20px;
	background-color: #000000;
	padding-left: 5px;
	img {
		background: #fff4cf;
		border-radius: 50%;
	}
	div {
		display: flex;
		font-size: 12px;
		font-weight: bold;
		color: white;
		.total-connection-count {
			font-weight: normal;
			margin-left: 3px;
		}
	}
`

const ConnectionListPanel = styled.div`
	display: flex;
	align-items: center;
	box-sizing: border-box;
	width: 107px;
	height: 17px;
	margin-left: 23px;
	margin-top: 3px;
`

const ConnectionListCurveArrow = styled.div`
	margin-top: -8px;
	img {
		width: 15px;
		height: 8px;
	}
`

const ConnectionListItem = styled.div`
	display: flex;
	box-sizing: border-box;
	cursor: pointer;
	padding-left: 5px;
	gap: 5px;
	border: 1px solid
		${({ isSupporting, isOpposing, isUndefined }) =>
			isSupporting || isOpposing || isUndefined ? '#FFC300' : 'transparent'};
	background-color: ${({ isSupporting, isOpposing, isUndefined }) =>
		isSupporting || isOpposing || isUndefined ? '#FFF4CF' : ''};
	border-radius: ${({ isSupporting, isOpposing, isUndefined }) =>
		isSupporting || isOpposing || isUndefined ? '20px' : '0px'};
	img {
		border-radius: 50%;
	}
	div {
		display: flex;
		font-size: 10px;
		font-weight: bold;
		.connetion-count {
			margin-left: 3px;
			font-weight: normal;
		}
	}
	:hover {
		border-radius: 20px;
		border: 1px solid #ffc300;
		background-color: #ffd44a;
	}
`

const TypesHelpers = styled.div`
	width: auto;
	height: 4.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: white;
	align-items: flex-start;
	padding: 0 0.5rem;
	font-size: 0.8rem;
	z-index: 0;
	p {
		margin: 0;
	}
`

const TypeDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	img {
		width: 1rem;
		margin-right: 0.5rem;
	}
`

const CreateConnectionToolTip = styled(Tooltip)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
`

const TypesContainer = styled.div`
	height: 100%;
	position: relative;
`

const ResetBtn = styled.div`
	/* position: absolute; */
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	width: 60px;
	height: 25px;
	gap: 3px;
	padding: 10px;
	border-radius: 20px;
	background-color: #ffffff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		/* transform: translateY(-2px); */
	}
	&:active {
		transform: translateY(0);
		transition: background 400ms;
	}
`

const SummaryCardContainer = styled.div`
	position: absolute;
	right: 2px;
	width: 230px;
	height: ${({ missingElementSuggestion, missingConnSuggestion, missingBeatConnection }) =>
		missingElementSuggestion || missingConnSuggestion
			? '530px'
			: missingBeatConnection
			? '545px'
			: '458px'};
	top: ${({ width }) => (width >= 518 ? '2px' : '130px')};
	border: 2px solid ${({ summaryCardVisible }) => summaryCardVisible ==='Premise'? '#ffc300' : nodeColors[summaryCardVisible]};
	border-radius: 10px;
	background: white;
`

const CloseSummaryCard = styled.div`
	position: absolute;
	width: 15px;
	height: 15px;
	top: 8px;
	left: 206px;
	cursor: pointer;
`

const StoryNameListItem = styled.div`
	display: flex;
	position: absolute;
	align-items: center;
	width: 190px;
	height: 30px;
	top: 15px;
	left: 17px;
	padding: 1px;
	gap: 2px;

	.summaryCardStoryName {
		font-weight: bold;
		font-size: 14px;
	}

	input {
		border: none;
		width: 172px;

		:focus {
			//outline: none;
		}
	}
`

const StorySummaryListItem = styled.div`
	display: flex;
	position: absolute;
	align-items: center;
	width: 195px;
	height: 20px;
	top: 50px;
	left: 18px;
	gap: 5px;

	.summaryCardSummary {
		font-weight: bold;
		font-size: 11px;
	}
`

const StorySummaryBox = styled.div`
	position: absolute;
	width: 193px;
	height: 63px;
	top: 69px;
	left: 18px;
	//outline: none !important;
	border: none !important;
	/* margin-top: 2px; */
	font-size: 10px;

	textarea {
		width: 100%;
		font-size: 11px;
		border: none;
		padding: 0px;
		resize: none;
		font-family: 'Source Sans Pro';

		:focus {
			//border: none;
			//outline: none;
		}

		::-webkit-scrollbar {
			width: 0px;
		}
	}

	.ql-editor {
		padding: 0px;
		font-size: 10px;
		/* outline: none;
		border: none; */
	}
`

const PremiseRingData = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 229px;
	height: 18px;
	top: 164px;
	background: #e7d395;
	border-radius: 5px 5px 0px 0px;

	.premiseTooltip {
		width: 44px;
		height: 8px;
		font-weight: bold;
		font-size: 12px;
		color: #ffffff;
		padding-left: 10px;
	}

	img {
		padding-top: 3px;
		padding-right: 3px;
	}
`

const MissingElementContainer = styled.div`
	position: absolute;
	width: 194px;
	height: 60px;
	top: 198px;
	left: 18px;

	.missingElementProgress {
		display: flex;

		.missingElementProgressItem {
			width: 100%;
			padding-left: 7px;
		}
	}

	.missingElementText {
		font-size: 9px;
		font-weight: 400;
		width: 139px;
		height: 15px;
		padding-left: 25px;
		padding-top: 5px;
	}
`

const MissingConnectionsContainer = styled.div`
	position: absolute;
	width: 194px;
	height: 60px;
	top: ${({ missingElementSuggestion }) => (missingElementSuggestion ? '368px' : '282px')};
	left: 18px;

	.missingConneProgress {
		display: flex;

		.missingConnProgressItem {
			width: 100%;
			padding-left: 7px;
		}
	}

	.missingConnectionText {
		font-size: 9px;
		font-weight: 400;
		width: 139px;
		height: 15px;
		padding-left: 25px;
		padding-top: 5px;
	}
`

const MissingConnectionSuggestion = styled.div`
	position: absolute;
	width: 200px;
	height: ${({ missingConnSuggestion, missingElementSuggestion, missingBeatConnection }) =>
		missingElementSuggestion
			? '94px'
			: missingConnSuggestion
			? '88px'
			: missingBeatConnection
			? '103px'
			: '85px'};
	top: 65px;
	background: #F3F4F5 !important;
	border-radius: 5px;
	border: 1px solid #e4e4e4;

	img {
		width: 200px;
	}
`

const MissingBeatConnectionContainer = styled.div`
	position: absolute;
	width: 194px;
	height: 61px;
	top: ${({ missingElementSuggestion, missingConnSuggestion }) =>
		missingConnSuggestion || missingElementSuggestion ? '448px' : '370px'};
	left: 18px;

	.missingConneProgress {
		display: flex;

		.missingConnProgressItem {
			width: 100%;
			padding-left: 7px;
		}
	}

	.missingConnectionText {
		font-size: 9px;
		font-weight: 400;
		width: 139px;
		height: 15px;
		padding-left: 25px;
		padding-top: 5px;
	}
`

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 3px;
	.title {
		font-family: 'Source Sans Pro';
		font-weight: 600;
		font-size: 9px;
		color: #181818;
	}

	.status {
		font-family: 'Source Sans Pro';
		font-size: 9px;
		/* color: #535353; */
	}

	.left {
		display: flex;
		align-items: center;
		gap: 7px;
	}

	.progressBar {
		position: absolute;
		left: 5px;
	}

	@media (max-width: 600px) {
		.title {
			font-size: 9px;
		}
		.status {
			font-size: 7px;
		}
		margin-bottom: 0;
	}
`

const SummaryCardSuggestion = styled.div`
	position: absolute;
	display: flex;
	width: 122px;
	height: 7px;
	top: 50px;
	left: 20px;
	background: #F3F4F5 !important;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	cursor: pointer;

	.suggestionText {
		font-size: 9px;
		font-weight: 700;
		padding-right: 3px;
	}
`

const SummaryCardMissingBeatSuggestion = styled.div`
	position: absolute;
	display: flex;
	width: 90px;
	height: 7px;
	top: 50px;
	left: 20px;
	background: #F3F4F5 !important;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	cursor: pointer;

	.suggestionText {
		font-size: 9px;
		font-weight: 700;
		padding-right: 3px;
	}
`

const SummaryCardWatchVideos = styled.div`
	position: absolute;
	bottom: ${({ missingElementSuggestion, missingBeatConnection }) =>
		missingElementSuggestion ? '90px' : missingBeatConnection ? '99px' : '84px'};
	left: ${({ missingBeatConnection }) => (missingBeatConnection ? '112px' : '142px')};
`

const ListIcon = styled.div`
	position: absolute;
	top: 141px;
	left: 185px;
`

const IconContainer = styled.button`
	cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
	background-color: transparent;
	padding: 0;
	img {
		width: 12px;
		cursor: pointer;
	}
	border: 0;
	margin: 2px;
`

export {
	VizContainer,
	Bar,
	ConnectionListContainer,
	ConnectionListTitle,
	ConnectionListPanel,
	ConnectionListCurveArrow,
	ConnectionListItem,
	VizContent,
	DeepModeContainer,
	Button,
	StyledFullscreen,
	TypeDiv,
	CreateConnectionToolTip,
	Btnwrap,
	TypesContainer,
	TypesHelpers,
	ResetBtn,
	SummaryCardContainer,
	CloseSummaryCard,
	StoryNameListItem,
	StorySummaryListItem,
	StorySummaryBox,
	PremiseRingData,
	MissingElementContainer,
	MissingConnectionsContainer,
	MissingBeatConnectionContainer,
	Title,
	SummaryCardSuggestion,
	MissingConnectionSuggestion,
	SummaryCardWatchVideos,
	IconContainer,
	ListIcon,
	SummaryCardMissingBeatSuggestion,
}
