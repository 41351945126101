import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useQuill } from 'react-quilljs'
import debounce from 'lodash/debounce'
import ThreeDot from '../../images/ThreeDot.svg'
import { Menu, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
	ShadowBeatContent,
	ShadowBeatCard,
	MenuButton,
	OptionsWrapper,
	DriversContainer,
	ElementIconContainer,
	NodeIconContainer,
	AdditionalItemContainer,
} from './styles'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { systemStateContext } from '../../state/systemProvider'
import { beatsDataStateContext } from '../../state/beatsProvider'
import ToolTip from '../ToolTip'

const useStyles = makeStyles(() => ({
	tooltip: {
		backgroundColor: '#0D5D56',
		borderRadius: '4px',
		color: '#FFFFFF',
		position: 'relative',
		top: '25px',
	},
	root: {
		fontSize: '12px',
		lineHeight: '10px',
		fontFamily: 'Source Sans Pro',
		color: '#000000',
		padding: '4px 6px',
	},
	list: {
		padding: 0,
	},
}))

function ShadowBeat({
	beatId,
	beat,
	beatConnection,
	referenceElement,
	referenceElementType,
	beatDrivers,
	cursor,
}) {
	const classes = useStyles()
	const { removeBeatConnection, currentElement } = useContext(beatsDataStateContext)
	const systemRelatedData = useContext(systemStateContext)
	const graphData = useContext(graphDataStateContext)

	const [isCollapsed, setIsCollapsed] = useState(true)
	const [anchorEl, setAnchorEl] = useState(false)

	const { quill, quillRef } = useQuill({
		modules: {
			toolbar: false,
			history: {
				delay: 1000,
				maxStack: 500,
				userOnly: true,
			},
		},
		readOnly: true,
	})

	useEffect(() => {
		const beat = graphData.nodes[beatId]
		if (beat && quill) {
			try {
				const description = JSON.parse(beat.description)
				if (description?.ops) {
					quill.setContents(description)
				} else {
					quill.setText(beat.description)
				}
			} catch (err) {
				quill.setText(beat.description)
			}
		}
	}, [graphData, quill])

	useEffect(() => {
		if (!isCollapsed) {
			setIsCollapsed(true)
		}
	}, [currentElement])

	const handleClose = () => {
		setAnchorEl(null)
	}
	const menuClickHandler = event => {
		systemRelatedData?.createLog(
			'Options Menu Clicked',
			`{"referenceElement": "${referenceElementType}"}`,
			'BeatCard',
			null,
		)
		setAnchorEl(event.currentTarget)
	}

	const removeBeatDebounce = useCallback(
		debounce(() => {
			removeBeatConnection(beatConnection, referenceElement)
		}, 400),
		[beatConnection, referenceElement],
	)

	const handleRemoveBeat = () => {
		handleClose()
		systemRelatedData?.createLog('Remove Beat Clicked', `{}`, 'BeatCard', 'Options Menu')
		// used debounce to avoid double clicks
		removeBeatDebounce()
	}

	const getTrimmedNodeName = node => {
		if (node?.name) {
			return node?.name?.length > 26 ? `${node?.name?.substring(0, 26)} ...` : node?.name
		}
		return ''
	}

	return (
		<ShadowBeatCard isMenuOpen={Boolean(anchorEl)} cursor={cursor}>
			<div className="quill-container">
				<ShadowBeatContent
					translate="no"
					data-testid="shadow-beat"
					ref={quillRef}
					isCollapsed={isCollapsed}
					onClick={() => {
						if (isCollapsed) {
							setIsCollapsed(false)
						}
					}}
				/>
				<MenuButton
					aria-controls="options-menu"
					aria-haspopup="true"
					onClick={menuClickHandler}
					id="menu-button"
					style={{ marginRight: '4px' }}
				>
					<img src={ThreeDot} alt="three dot icon" />
				</MenuButton>
			</div>
			<Menu
				id="options-menu"
				anchorEl={anchorEl}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				classes={{ list: classes.list }}
			>
				<MenuItem
					classes={{ root: classes.root }}
					onClick={handleRemoveBeat}
					aria-controls="connection-modal"
					aria-haspopup="true"
				>
					Remove from Chapter
				</MenuItem>
			</Menu>
			<OptionsWrapper>
				{beat?.driverConnections?.length > 0 && (
					<DriversContainer>
						<div id='driver-container' className="drivers-container" onDoubleClick={e => e.stopPropagation()}>
							{beatDrivers?.slice(0, 3).map(node => {
								const name = getTrimmedNodeName(node)
								return (
									<ToolTip key={node?.id} title={name} placement="top" arrow>
										<ElementIconContainer
											id={`${node?.id}-${beatId}`}
											style={{ cursor: 'default' }}
										>
											<NodeIconContainer type={node?.__typename} isDriver={true}>
												<span>
													{node?.name
														?.match(/\b(\w)/g)
														?.join('')
														.slice(0, 2)
														.toUpperCase()}
												</span>
											</NodeIconContainer>
										</ElementIconContainer>
									</ToolTip>
								)
							})}
							{beatDrivers?.length > 3 && (
								<AdditionalItemContainer>
									<span>+{beatDrivers.length - 3}</span>
								</AdditionalItemContainer>
							)}
						</div>
					</DriversContainer>
				)}
			</OptionsWrapper>
		</ShadowBeatCard>
	)
}

export default memo(ShadowBeat)
