/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import React, {
	useEffect,
	useContext,
	useRef,
	useCallback,
	cloneElement,
	useLayoutEffect,
	useState,
} from 'react'
import { useApolloClient } from '@apollo/client'
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensors,
	useSensor,
	MeasuringStrategy,
	pointerWithin,
	rectIntersection,
} from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useHistory, useParams } from 'react-router-dom'

import orderBy from 'lodash/orderBy'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useIdleTimer } from 'react-idle-timer'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { systemDispatchContext, systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	graphDataDispatchContext,
	setGraphData as sharedSetGraphData,
	resetGraphData as sharedResetGraphData,
	graphDataStateContext,
} from '@lynit/shared/src/state/graphDataProvider'
import {
	moveBeatConnection,
	reorderElement,
	setCurrentFlow,
	setCurrentStoryId,
	setDeepModeElement,
	setIsMobile,
	setLeftToggleTabs,
	setNewConnId,
	setNoElementError,
	setRightToggleTabs,
	setServerError,
	setUserSubscribed,
	setContextDeepModeConns,
	setSummaryCardVisible,
	setActiveConnections,
} from '@lynit/shared/src/state/actions'
import useRecommendations from '@lynit/shared/src/hooks/useRecommendations'
import Navbar from '@lynit/shared/src/ui/Navbar'
import NoNetworkOverlay from '@lynit/layout/src/ui/NoNetworkOverlay'
import ElementList from '@lynit/layout/src/ui/ElementList'
import RightSidePanel from '@lynit/layout/src/ui/RightSidePanel'

import DragOverlayComponent from '@lynit/layout/src/ui/DragOverlay'
import { createNodeId } from '@lynit/shared/src/utils/utils'
import getConnections from '@lynit/shared/src/utils/getConnections'
import {
	createCacheConnection,
	deleteCacheConnections,
	updateCacheOrderElement,
	updateCacheOrderBeat,
	moveBeatCacheUpdate,
	updateCacheField,
	parseGraphData,
} from '@lynit/shared/src/utils/apollo'

import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import splitter from '../../images/splitter.svg'
import welcomeVisualization from '@lynit/shared/src/images/supporting-structure-tag.svg'
//import welcomeVisualization from '../../images/welcome-visualization.svg'
import {
	useDeleteNodeMutation,
	useCreateConnectionMutation,
	useDeleteConnectionMutation,
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useReorderNodesMutation,
	useMoveBeatMutation,
	useChangeIsFirstLoginMutation,
	useUpdateRelationshipMutation,
	useGetAllNodesQuery,
} from '@lynit/shared/src/hooks'
import { Card, Container, BaseContainer } from './styles'

import introJs from 'intro.js'


import 'intro.js/introjs.css'
import './tooltip.css'
import DeepModeElement from '@lynit/deep-mode-element/src/ui/DeepModeElement'
import StoryOutline from '@lynit/story-outline/src/ui/StoryOutline/StoryOutline'


const Layout = () => {
	const {
		state: {
			recommendationId,
			story,
			tutorialId,
			leftToggleTabs,
			rightToggleTabs,
			activeConnections,
			isActive,
			currentStory,
			cacheConnData: { newConnId },
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const types = currentStory?.lastDocView === "Writing" ? ['Chapter'] : ['Arc', 'Character', 'Event', 'Theme']

	const initialVal = {
		active: currentStory?.lastDocView === "Writing" ? types[0] : types[1],
		isMutatedConn: false,
		connectionModal: false,
		isChangeStoryModal: false,
		deepModeConnections: [],
		isDelete: false,
		filteredNodes: [],
		isDriver: null,
		driverConnToDelete: null,
		networkOverlay: true,
		isOnboardingDone: false,
		currentElementDel: {},
		chapterGridCol: '33% 50% 16%',
		isNewRecommendations: false,
		currentView: 'visualization',
		currentChapter: {},
		isExpandingView: false,
		recommendations: [],
		WTDNActiveLink: 'recommendation',
		previousCompletedRecommendations: null,
		previousStory: '',
		isUserNotActive: null,
		isChapterTabOpen: false,
	}

	const reduceFn = (state, action) => {
		return { ...state, [action.key]: action.value }
	}

	const { currentElement, currentStoryId, deepModeElement, serverError } =
		useContext(beatsDataStateContext)
	const { user, refetchUser } = useContext(userStateContext)
	const { isUserSubscribed, isOnline, createLog } = useContext(systemStateContext)

	const graphDataDispatch = useContext(graphDataDispatchContext)
	const systemDataDispatch = useContext(systemDispatchContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	
	const history = useHistory()

	const { data, loading:allNodesLoading, refetch, error } = useGetAllNodesQuery({ fetchPolicy: 'network-only'})
	  
	if (error?.message?.includes('Wrong storyId format') || error?.message?.includes("StoryId Doesn't Exist")) {


		history.push('/dashboard')
	}
	
	
	const [graphData, setGraphDataLocal] = useState(parseGraphData({
		data: {
			nodes: data?.nodes,
			loading:allNodesLoading,
			refetch,
			error,
		},
	}))
	//const graphData = 
	//eslint-disable-next-line
	const [state, dispatch] = React.useReducer(reduceFn, initialVal)
	
	const client = useApolloClient()
	const { story: storyParams } = useParams()

	const {
		loading,
		data: relationships,
		refetch: refetchRelations,
	} = useGetRelationshipsQuery({ fetchPolicy: 'network-only' })


	const [changeIsFirstLogin] = useChangeIsFirstLoginMutation()

	const [performCreateConnectionMutation] = useCreateConnectionMutation({ ignoreResults: true })
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()

	const [deleteNode, { data: deletedNode }] = useDeleteNodeMutation()
	const [reorderNodes] = useReorderNodesMutation()
	const [reorderBeats] = useReorderBeatsMutation()
	const [moveBeat, { loading: response }] = useMoveBeatMutation()
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation()

	const [isSetModifiers, setIsSetModifiers] = useState(false)

	const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')
	const {isMobile} = useContext(systemStateContext)

	const [stepsEnabled,setStepEnabled] = useState(false)
	const [initialStep,setInitialStep] = useState(0)
	//const [steps,setSteps] = useState(false)
	const [hintsEnabled,setHintsEnabled] = useState(false)
	const tourSteps = {}
	tourSteps["premiseIntro"] = [
		// {
		//   intro: `<p><strong style="color: #FFC300;">Welcome to&nbsp;Lynit</strong><strong>&nbsp;👋</strong></p>
		//   <p>Let's walk through some features of Lynit by starting with your story's Premise!</p>
		//   <p>In this intro you can learn how to structure a Premise with fundamental outlining techniques like Connections, Beats, and Drivers.</p>`,
		//  //element: "#ring",
		// },

		//<img src="https://lynit.s3.us-east-2.amazonaws.com/welcome-visualization.svg"/>
		{	title:`<strong style="color: #FFC300;">Outlining&nbsp;with&nbsp;Lynit</strong>`,
			intro: `
			<p>Use our <strong>Connections, Beats, and Drivers</strong> to outline and visualizualize your story's structure.</p>
			<p>Let's walk through the basics of each feature by learning how to setup your story's Premise!</p>
			`,
		   element: "#visualization",
		   position: "left"
		},
		{	title:`<strong style="color: #FFC300;">Meet Your Premise</strong>`,
			intro: `
			This is where the core elements of your story live. Your Premise is complete when:
			<ul>
				<li>You have at least one Arc, Character, and TSM present.</li>
				<li>There is a Connection between each pair of Premise Elements.</li>
				<li>Each Connection Drives at least one Beat.</li>
			</ul>
			`,
			element: "#ring",
		},
		{	
			title:`<strong style="color: #FFC300;">Premise Summary</strong>`,
			intro: `
			Your story's basic information is here as well as the status of your Premise. 
			<p><span style="color: rgb(144, 101, 176);">✨ Try to: Name your story and write a description!</span> </p>
			<p>Now let's setup your Premise together. Check the progress bars here to see what your Premise is missing.</p>
			`
			,
		   	element: "#summaryCard",
		},
		// {	
		// 	title:`<strong style="color: #FFC300;">Summary Card</strong>`,
		// 	intro: `
		// 	The Summary Card guides you in what you can do to set up the Premise of your story.
		// 	<p><span style="color: rgb(144, 101, 176);">✨ Try to: Name your story and write a description!</span> </p>`,
		//    	element: "#summaryCard",
		// },
		// {
		// 	title:`<strong style="color: #FFC300;">Premise Status</strong>`,
		// 	intro: `
		// 	Here you can follow along to see the progress of your Premise.
		// 	<p>You can complete the Premise by:</p>
		// 	<ul>
		// 		<li>Adding Premise Elements</li>
		// 		<li>Connecting the Elements</li>
		// 		<li>Identifying the Driver of Beats</li>
		// 	</ul>
		// 	<p>We will go through each action in detail together.</p>`,
		//    	element: "#summaryCard",
		// 	position:"left-middle-aligned"
		// },
	  ]
	
	tourSteps["addElementsToPremise"] = [
		
		{	title:`<strong style="color: #FFC300;">Interact&nbsp;with&nbsp;Your&nbsp;Story</strong>`,
			intro: `
			Your Story Premise is not complete until it includes at least one Arc, Character, and TSM.
			<p>To add an Element to the Premise, simply drag it into the yellow ring. </p>
			<p><span style="color: rgb(144, 101, 176);">✨ Try to: Drag the "Main Plot" into the Premise Ring!</span> </p>
			`,
		   element: ".Arc",
		   position: "left"
		},
		{	title:`<strong style="color: #FFC300;">Great!</strong>`,
			intro: `
			Notice the new yellow dotted lines in the Premise. These indicate the missing connections between your Premise Elements. 

			<p><strong>All premise elements should be connected to each other.</strong></p>
			`,
			element: "#ring",
			position: "left"
		},
		
	  ]
	
	tourSteps["createConnections"] = [
		
		
		{	title:`<strong style="color: #FFC300;">Element Card</strong>`,
			intro: `
			We opened the "Main Plot" Element Card.
			Here you you can:
			<ul>
				<li>Edit the name and description.</li>
				<li>Open/Close the Document (Freeform Text Editor).</li>
				<li>Create Connections.</li>
				<li>Create Beats.</li>
			</ul>

			`,
			element: "#elementListContainer",
			position: "right"
		},
		{	title:`<strong style="color: #FFC300;">Connections</strong>`,
			intro: `
			Connections represent the relationships between your elements.
			<p>The "Main Plot" is missing:</p>
			<ul>
				<li>A Protagonist (Supporting) Connection to the Main Character. Supporting Connections capture harmony or complementary ideas.</li>
				<li>An Antagonist (Opposing) Connection to the Villain. Opposing Connections capture conflict, whether ideological, verbal, or physical.</li>
			</ul>

			<p><span style="color: rgb(144, 101, 176);">Try: Create the missing Connections.</span> </p>
			`,
		   element: "#connUsedAsBeatDrivers",
		   position: "right"
		},
		{	title:`<strong style="color: #FFC300;">Use Connections to Drive Beats</strong>`,
			intro: `
			There is one last step to finalizing a Connection and to turn the dotted line in the visualization solid. 
			<p>You need to use the Connection to Drive Beat.</p>
			
			`,
		   element: "#connUsedAsBeatDrivers",//"#simple-tabpanel-0",//"#elementListContainer",
		   position: "right"
		},


		
	  ]

	tourSteps["addDrivers"] = [
		
		
		{	title:`<strong style="color: #FFC300;">What are Beats?﻿</strong>`,
			intro: `
			Beats are the actions that advance an Element's development. 
			Any plot point, worldbuilding, Connection or key detail you want the reader to understand should show up in a Beat.
			
			<p><span style="color: rgb(144, 101, 176);">Try: Open the Beats tab.</span> </p>
			`,
			element: "#listElementTabs",
			position: "right"
		},
		{	title:`<strong style="color: #FFC300;">Add Drivers</strong>`,
			intro: `
			To capture how a connection specifically advances an element, we use Drivers.
			<p><span style="color: rgb(144, 101, 176);">Let's try: Hovering over a beat and clicking "Add Drivers"﻿. Choose the Connections that drive this Beat.</span></p>
			The Connection in the visualization will turn into a solid line, but this is just the start. The more beats a Connection drives, the more important it is in the story.
			`,
		   element: "#listElementTabs",
		   position: "right"
		},
		{	title:`<strong style="color: #FFC300;">Tell your best story</strong>`,
			intro: `
			Now you know the basic of outlining in Lynit.
			<p>When your Beats are ready to be placed in Chapters. Open the Chapter Planner at the bottom of your screen.</p>
			<p>To learn more about Connections, Beats, and Drivers check out Learn with Lynit.</p>
			<p><strong>Happy Writing!</strong></p>
 
			`,
		   element: "#ring",
		   position: "left"
		},
		

		
	  ]
	
	tourSteps["chapterPlanner"] = [
		
		{	title:`<strong style="color: #FFC300;">Chapter Planner</strong>`,
			intro: `
			Once your Beats have been planned. Drag them into the Chapters where they take place.
			
			<p>To learn more out Connections, Beats, and Drivers check out Learn with Lynit which you can find on your My Stories Dashboard.</p>
			<p><strong>Happy Writing!</strong></p>
 
			
			
			`,
		   element: "#chp-bar",
		   position: "top"
		},
		{	title:`<strong style="color: #FFC300;">Tell your best story</strong>`,
			intro: `
			Now you know the basic of outlining in Lynit.
			<p>To learn more out Connections, Beats, and Drivers check out Learn with Lynit which you can find on your My Stories Dashboard.</p>
			<p><strong>Happy Writing!</strong></p>
 
			
			
			`,
		   element: "#ring",
		   position: "left"
		},
		

		
	  ]
	
	const hints = [
		{
		  element: "#elementListContainer",
		  hint: "Hello hint",
		  hintPosition: "middle-right"
		}
	  ]
	let introJsTourMap = useRef({})
	
		
	useEffect(()=>{

		setTimeout(()=>{
			
			if((! introJsTourMap?.current?.premiseIntro?.isActive()) && (user?.userSummary?.nextOnboardingTourStage === "premiseIntro") && !(user?.userSummary?.exitOnboardingTourStage) ){

				introJsTourMap.current.premiseIntro = 
					introJs("#visualization").setOptions({
					steps:tourSteps["premiseIntro"],
					showProgress: true,
					tooltipClass:"difclass",
					overlayOpacity:0.8,
					doneLabel:"Add Premise Elements",
					exitOnOverlayClick:false,
					disableInteraction: false
			  	})

			
				introJsTourMap.current.addElementsToPremise = introJs("#elementListContainer").setOptions({
					steps:tourSteps["addElementsToPremise"],
					showProgress: true,
					tooltipClass:"difclass",
					overlayOpacity:0,
					doneLabel:"Let's Create Connections!",
					exitOnOverlayClick:false,
					disableInteraction: false
				})


				introJsTourMap.current.createConnections = introJs("#tourStart3").setOptions({
					steps:tourSteps["createConnections"],
					showProgress: true,
					tooltipClass:"difclass",
					overlayOpacity:0.8,
					doneLabel:"Let's Drive a Beat!",
					exitOnOverlayClick:false,
					disableInteraction: false
				})

				introJsTourMap.current.addDrivers = introJs("#navBar").setOptions({
					steps:tourSteps["addDrivers"],
					showProgress: true,
					tooltipClass:"difclass",
					overlayOpacity:0.8,
					doneLabel:"Start Writing!",
					exitOnOverlayClick:false,
					disableInteraction: false
				})
			

				Object.keys(introJsTourMap.current).forEach(key =>{

					if(key ==="skipButtonClicked"){
						return 
					}


					introJsTourMap.current[key].onskip(()=> {
						
						createLog(
							'Onboarding Tour Exited Early',
							`{"tourName":"${key}", "user_properties":{"exitOnboardingTourStage":"${key}"}}`,
							'Layout',
							null,
						)

					})

				})

				introJsTourMap.current.premiseIntro.onafterchange(function(value) {

					if(this._currentStep===0){

						document.getElementsByClassName('introjs-skipbutton')[0].onclick = function() {
							introJsTourMap.current.premiseIntro.exit(true)
							createLog(
								'Onboarding Tour Exited Early',
								`{"tourName":"premiseIntro", "user_properties":{"exitOnboardingTourStage":"premiseIntro"}}`,
								'Layout',
								null,
							)

						};
					}

					if(!document.getElementsByClassName('introjs-overlay')?.[0]?.classList.contains("noShowIntroOverlay")){
						document.getElementsByClassName('introjs-overlay')?.[0]?.classList.add("noShowIntroOverlay")
					}
					// if(!document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.contains("noShowIntroOverlay")){
					// 	document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.add("noShowIntroOverlay")
					// 	}

					
				});

				
				introJsTourMap.current.addElementsToPremise.onafterchange(function(value) {

					if(this._currentStep===0){

						document.getElementsByClassName('introjs-skipbutton')[0].onclick = function() {
							introJsTourMap.current.addElementsToPremise.exit(true)
							createLog(
								'Onboarding Tour Exited Early',
								`{"tourName":"addElementsToPremise", "user_properties":{"exitOnboardingTourStage":"addElementsToPremise"}}`,
								'Layout',
								null,
							)

						};
					}

					if(!document.getElementsByClassName('introjs-overlay')?.[0]?.classList.contains("noShowIntroOverlay")){
						document.getElementsByClassName('introjs-overlay')?.[0]?.classList.add("noShowIntroOverlay")
					}
					if(!document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.contains("noShowIntroOverlay")){
						document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.add("noShowIntroOverlay")
					}
					
						
					
				});

				introJsTourMap.current.createConnections.onafterchange(function(value) {

					if(this._currentStep===0){
						document.getElementsByClassName('introjs-skipbutton')[0].onclick = function() {
							introJsTourMap.current.createConnections.exit(true)
							createLog(
								'Onboarding Tour Exited Early',
								`{"tourName":"createConnections", "user_properties":{"exitOnboardingTourStage":"createConnections"}}`,
								'Layout',
								null,
							)

						};
					}

					if(this._currentStep>0){
						if(!document.getElementsByClassName('introjs-overlay')?.[0]?.classList.contains("noShowIntroOverlay")){
							document.getElementsByClassName('introjs-overlay')?.[0]?.classList.add("noShowIntroOverlay")
						}
						if(!document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.contains("noShowIntroOverlay")){
						document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.add("noShowIntroOverlay")
						}
					}
					
				});

				introJsTourMap.current.addDrivers.onafterchange(function(value) {

					if(this._currentStep===0){
						document.getElementsByClassName('introjs-skipbutton')[0].onclick = function() {
							introJsTourMap.current.addDrivers.exit(true)
							createLog(
								'Onboarding Tour Exited Early',
								`{"tourName":"addDrivers", "user_properties":{"exitOnboardingTourStage":"addDrivers"}}`,
								'Layout',
								null,
							)

						};
					}

					if(!document.getElementsByClassName('introjs-overlay')?.[0]?.classList.contains("noShowIntroOverlay")){
						document.getElementsByClassName('introjs-overlay')?.[0]?.classList.add("noShowIntroOverlay")
					}

					if(this._currentStep>0){
						if(!document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.contains("noShowIntroOverlay")){
						document.getElementsByClassName('introjs-helperLayer')?.[0]?.classList.add("noShowIntroOverlay")
						}
					}
					
				});

				

				introJsTourMap.current.premiseIntro.oncomplete(function() {
					
					createLog(
						'Onboarding Tour Section Completed',
						`{"tourName":"${"premiseIntro"}","order":${1}, "user_properties":{"nextOnboardingTourStage":"addElementsToPremise"}}`,
						'Layout',
						null,
					)
		
					setTimeout(() => {
		
						introJsTourMap.current.addElementsToPremise.start()
						
					},500)
					
					});
		
		
				introJsTourMap.current.createConnections.oncomplete(function() {
		
					
						createLog(
							'Onboarding Tour Section Completed',
							`{"tourName":"${"createConnections"}","order":${3}, "user_properties":{"nextOnboardingTourStage":"addDrivers"}}`,
							'Layout',
							null,
						)
		
							setTimeout(() => {
								introJsTourMap.current.addDrivers.start()
							
							},500)

					});
					
		
				introJsTourMap.current.addDrivers.oncomplete(function() {
		
					createLog(
						'Onboarding Tour Section Completed',
						`{"tourName":"${"addDrivers"}","order":${4}, "user_properties":{"nextOnboardingTourStage":"OnboardingCompleted"} }`,
						'Layout',
						null,
					)
					
					});




				introJsTourMap.current.premiseIntro.start();
	
			}
			
	
	
			  
			// introJs().setOptions({
			// 	hints,
			//   }).addHints()

			//introJsTourMap.current.premiseIntro.start();
			//introJsTourMap.current.addElementsToPremise.start()
		},2000)


	},[user?.userSummary])



	// useEffect(()=>{
	// 	
	// 	if((introJsTourMap?.current?.premiseIntro) && (user?.userSummary?.nextOnboardingTourStage === "premiseIntro") && !(user?.userSummary?.exitOnboardingTourStage) ){
	// 		introJsTourMap.current.premiseIntro.start();

	// 			}

	// },[user?.userSummary,introJsTourMap?.current?.premiseIntro ])


	useEffect(() => {		
		if(introJsTourMap.current?.addElementsToPremise?.isActive()) {

			const foundArc = graphData?.nodesByType?.["Arc"].find(node => node.name ==="Main Plot") ||graphData?.nodesByType?.["Arc"][0]
			if(!foundArc){
				// Maybe create an arc if none exist
			}
			if(foundArc ) {
				introJsTourMap.current.addElementsToPremise.oncomplete(function() {
					

					createLog(
						'Onboarding Tour Section Completed',
						`{"tourName":"${"addElementsToPremise"}","order":${2}, "user_properties":{"nextOnboardingTourStage":"createConnections"}}`,
						'Layout',
						null,
					)
					sharedDispatch(setActiveConnections({ isActive: true, elementId: foundArc.id, elementType: foundArc.__typename }))

					setTimeout(() => {

						
						
						
						introJsTourMap.current.createConnections.start()
						//And finally the beats tour is after this.
						
					},500)
					

					
					});
			}

		}
		
		

		
	}, [graphData,introJsTourMap.current?.addElementsToPremise?._currentStep,introJsTourMap.current.skipButtonClicked])



	useLayoutEffect(() => {
		sharedDispatch(setCurrentFlow('My Stories'))
		if (user?.isFirstLogin) {
			changeIsFirstLogin()
		}
	}, [])

	// if story is available on params then story id sets in context
	useEffect(() => {
		if (storyParams) {
			sessionStorage.setItem('story', storyParams)
			beatsDataDispatch(setCurrentStoryId(storyParams))
		}
	}, [])

	// if user click on tutorial link from deep mode of an element then exit from deep mode
	useEffect(() => {
		if (currentElement && (tutorialId || recommendationId)) {
			beatsDataDispatch(setDeepModeElement(null))
			// history.push(`/story/${currentStoryId}`)
		}
	}, [tutorialId, recommendationId, currentElement, currentStoryId])

	useEffect(() => {
		if (
			!state.isUserNotActive &&
			isActive &&
			!(
				user?.subscriptionStatus?.status === 'trialing' &&
				!user?.subscriptionStatus?.subscriptionSchedule
			)
		) {
			dispatch({ key: 'isUserNotActive', value: true })
		} else if (state.isUserNotActive) {
			dispatch({ key: 'isUserNotActive', value: false })
		}
	}, [isActive])

	useEffect(() => {
		if (deepModeElement?.nodeType === 'Chapter') {
			dispatch({ key: 'currentChapter', value: graphData.nodes[deepModeElement.id] })
		} else if (!deepModeElement) {
			dispatch({ key: 'currentChapter', value: {} })
		}
	}, [deepModeElement?.id])

	useEffect(() => {
		if (isMobile) {
			sharedDispatch(setRightToggleTabs(false))
			sharedDispatch(setLeftToggleTabs(false))
		}
	}, [isMobile])

	useEffect(() => {
		if (deepModeElement) {
			beatsDataDispatch(setDeepModeElement(null))
		}
	}, [story])

	useEffect(() => {
		createLog(
			'Screen Size',
			`{"width":${window?.innerWidth},"height":${window?.innerHeight}}`,
			'ParentContainer',
			null,
		)
	}, [])


	useEffect(() => {
		if (state.isMutatedConn) {
			dispatch({ key: 'isMutatedConn', value: false })
		}
		if (currentElement) {
			dispatch({ key: 'isDeepMode', value: true })
			if (!loading && !state.isMutatedConn) {
				dispatch({
					key: 'deepModeConnections',
					value: getConnections(currentElement, relationships?.relations, graphData?.nodes),
				})
			}

			if (graphData?.nodes[currentElement]) {
				// dispatch({
				// 	key: 'filteredNodes',
				// 	value: filterGraphData(graphData?.nodes, currentElement),
				// })
				setContextDeepModeConns(
					getConnections(currentElement, relationships?.relations, graphData?.nodes),
				)
			}
		}
		if (state.isDeepMode && (!currentElement || !deepModeElement)) {
			dispatch({ key: 'isDeepMode', value: false })
			dispatch({ key: 'connectionModal', value: false })
		}
	}, [currentElement, deepModeElement, graphData, relationships])

	useEffect(() => {
		
		if (rightToggleTabs && leftToggleTabs && isTablet) {
			dispatch({ key: 'chapterGridCol', value: '300px calc(100% - 380px) 70px' })
		} else if (rightToggleTabs && leftToggleTabs && !isTablet) {
			dispatch({ key: 'chapterGridCol', value: '470px calc(100% - 900px) 420px' })
		} else if (!rightToggleTabs && leftToggleTabs && isTablet) {
			dispatch({ key: 'chapterGridCol', value: '320px calc(100% - 400px) 70px' })
		} else if (!rightToggleTabs && leftToggleTabs && !isTablet) {
			dispatch({ key: 'chapterGridCol', value: '470px calc(100% - 550px) 70px' })
		} else if (rightToggleTabs && !leftToggleTabs && isTablet) {
			dispatch({ key: 'chapterGridCol', value: '200px calc(50% - 35px) calc(50% - 175px)' })
		} else if (rightToggleTabs && !leftToggleTabs && !isTablet) {
			dispatch({ key: 'chapterGridCol', value: '70px calc(100% - 500px) 420px' })
		} else {
			dispatch({ key: 'chapterGridCol', value: '75px calc(100% - 155px) 70px' })
		}
		
	}, [rightToggleTabs, leftToggleTabs, isTablet])

	useEffect(() => {
		if ((isMobile || isTablet) && leftToggleTabs && rightToggleTabs) {
			sharedDispatch(setRightToggleTabs(false))
		}
	}, [leftToggleTabs])

	useEffect(() => {
		if ((isMobile || isTablet) && rightToggleTabs && leftToggleTabs) {
			sharedDispatch(setLeftToggleTabs(false))
		}
	}, [rightToggleTabs])

	

	const linkNodes = useCallback(
		(id, isStatic, structureTag = '') => {
			if (relationships?.relations.some(rel => rel.id.includes('temp')) || newConnId) {
				return false
			}

			const rpElement = currentElement || activeConnections?.elementId
			const hasConnectionBeenMade = relationships?.relations?.some(
				// eslint-disable-next-line array-callback-return
				el => {
					if (isStatic) {
						return (
							(el?.connectionType === 'Static' &&
								el?.sourceNode?.id === id &&
								el?.destNode?.id === rpElement) ||
							(el?.connectionType === 'Static' &&
								el?.sourceNode?.id === rpElement &&
								el?.destNode?.id === id)
						)
					}
				},
			)
			if (!hasConnectionBeenMade) {
				const tempId = createNodeId('Relationship')

				const newConnection = {
					id: tempId,
					description: '',
					arcStage: null,
					relName: 'RELATED',
					order: null,
					connectionType: isStatic ? 'Static' : 'Beat',
					structureTag,
					arc: null,
					sourceNode: {
						id: rpElement,
						__typename: graphData?.nodes[rpElement]?.__typename,
					},
					destNode: {
						id,
						__typename: graphData?.nodes[id]?.__typename,
					},
					__typename: 'Relationship',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					name: null,
					beatsDriven:"0",
					sharedBeats:[],
					firstBeat: '',
					createdAt: new Date().toISOString()
				}
				sharedDispatch(setNewConnId(tempId))
				performCreateConnectionMutation({
					client,
					newConnection,
					user
				}
				)
			}
			if (hasConnectionBeenMade) {
				toastHandler('warning', 'Duplicated Connection')
			}
		},
		[relationships?.relations, graphData.nodes, currentElement, activeConnections, user, newConnId],
	)

	useEffect(() => {
		if (deletedNode?.deleteNode === currentElement) {
			dispatch({ key: 'isDeepMode', value: false })
			dispatch({ key: 'connectionModal', value: false })
			beatsDataDispatch(setDeepModeElement(null))
		}
	}, [deletedNode])

	useEffect(() => {
		
		const graphData = parseGraphData({
			data: {
				nodes: data?.nodes,
				loading:allNodesLoading,
				refetch,
				error,
			},
		})
		setGraphDataLocal(graphData)
		graphDataDispatch(sharedSetGraphData(graphData))
		
	}, [data?.nodes])

	useEffect(()=>{
		if (!localStorage.token) {
			return history.push('/login')
		}
	},[localStorage.token])

	const reorderElements = async result => {
		let current = state.active
		if (result.type === 'chapter') {
			current = 'Chapter'
		}
		const currentElements = graphData.nodesByType[current]
		const activeElements = currentElements?.map(element => {
			return { ...element, order: +element.order }
		})
		const filteringData = orderBy(activeElements, ['order'], ['asc'])
		if (result.destination.index === result.source.index) {
			return
		}
		const sourceIndex = result.source.index
		const orderOfSourceElement = filteringData[sourceIndex]?.order
		const destinationIndex = result.destination.index
		const orderOfDestinationElement = filteringData[destinationIndex]?.order
		// array contains elements whose order has been changed
		const existingOrders = []
		const mutationData = []
		// up direction drag
		if (orderOfSourceElement > orderOfDestinationElement) {
			filteringData.forEach(element => {
				if (element.order >= orderOfDestinationElement && element.order < orderOfSourceElement) {
					mutationData.push({
						id: element.id,
						order: String(element.order + 1),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
				if (element.order === orderOfSourceElement) {
					mutationData.push({
						id: element.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
			})
		}
		//down direction drag
		if (orderOfSourceElement < orderOfDestinationElement) {
			filteringData.forEach(element => {
				if (element.order > orderOfSourceElement && element.order <= orderOfDestinationElement) {
					mutationData.push({
						id: element.id,
						order: String(element.order - 1),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
				if (element.order === orderOfSourceElement) {
					mutationData.push({
						id: element.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
			})
		}
		createLog(
			'Nodes Reorder Attempted',
			`{"elementType":"${current}"}`,
			'ElementList',
			'Nodes Reorder',
		)
		if (mutationData.length) {
			updateCacheOrderElement(client, mutationData)
			beatsDataDispatch(reorderElement())
			reorderNodes({
				variables: {
					nodeType: current,
					newNodeOrder: mutationData,
				},
			})
				.then(res => {
					//updateCacheOrderElement(client, mutationData)
					//beatsDataDispatch(reorderElement())
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
					}
				})
				.catch(error => {
					toastHandler('error', 'Error while updating an order. Try again.')
					refetchRelations()
					console.error(error)
					updateCacheOrderElement(client, existingOrders)
				})
		}
	}

	const pauseFunction = async time => {
		console.log('start timer')
		await new Promise(resolve => setTimeout(resolve, time))
		console.log('after ', time / 1000, ' seconds')
	}
	const moveBeatHandler = async (oldChapterId, newChapterId, beatId, beatNewOrder) => {
		const oldChapter = graphData.nodes[oldChapterId]
		const newChapter = graphData.nodes[newChapterId]
		const beat = graphData.nodes[beatId]
		const beatOldOrder = beat?.beatConnections?.[0].order

		const mutationData = []
		const existingOrders = []
		const relId = createNodeId('Relationship')
		const tempData = {
			newRelationship: {
				__typename: 'Relationship',
				id: relId,
				arcStage: null,
				beatsDriven: null,
				connectionType: 'Beat',
				description: '',
				destNode: { id: newChapterId, __typename: 'Chapter' },
				order: beatNewOrder,
				relName: 'RELATED',
				sourceNode: { id: beatId, __typename: 'Beat' },
				structureTag: null,
				arc: null,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				name: null,
				sharedBeats:null,
				firstBeat: '',
				createdAt: new Date().toISOString()
			},
		}

		oldChapter?.beatConnections?.filter(conn => ![conn.sourceNode.id,conn.destNode.id].includes(beat.id)).sort((a, b) => { return (parseInt(a.order) - parseInt(b.order)) }).forEach((beatConnection,index) => {
			const bId =  beatConnection?.sourceNode?.id.startsWith('bea')
			? beatConnection?.sourceNode?.id
			: beatConnection.destNode.id
			

			mutationData.push({
				id: bId,
				order: String(index),
				chapter: "old",
				description: graphData.nodes[bId].description

			})
				
			existingOrders.push({
				id: beatConnection.id,
				order:  String(beatConnection.order),
				chapter: "old",
				description: graphData.nodes[bId].description
			})
			
		})

		newChapter?.beatConnections?.slice()?.sort((a, b) => { return (parseInt(a.order) - parseInt(b.order)) }).forEach((beatConnection,index) => {
			const bId =  beatConnection?.sourceNode?.id.startsWith('bea')
			? beatConnection?.sourceNode?.id
			: beatConnection.destNode.id
			if (index >= +beatNewOrder) {
				mutationData.push({
					id: bId,
					order: String(index + 1),
					chapter: "new",
					description: graphData.nodes[bId].description
				})
				
			}
			else{
				mutationData.push({
					id: bId,
					order: String(index),
					chapter: "new",
					description: graphData.nodes[bId].description
				})

			}
			existingOrders.push({
				id: beatConnection.id,
				order:  String(beatConnection.order),
				chapter: "new",
				description: graphData.nodes[bId].description
			})
		})

		if (oldChapterId && newChapterId && beatId && beatNewOrder && beat) {
			moveBeatCacheUpdate(
				client,
				beat?.beatConnections?.[0],
				mutationData,
				tempData?.newRelationship,
				true,
			)

			moveBeat({
				variables: {
					input: {
						relId,
						oldChapterId,
						newChapterId,
						beatId,
						beatNewOrder,
					},
				},
				ignoreResults: true,
			})
				.catch(error => {
					deleteCacheConnections(client, { ...tempData?.newRelationship, arc: null }, false)
					createCacheConnection(client, { ...beat?.beatConnections?.[0], arc: null }, false, true)
					console.error(error)
					toastHandler('error', 'There was an error moving a beat, try again.')
					//refetchRelations()
					if (existingOrders?.length) {
						updateCacheOrderBeat(client, existingOrders)
					}
				})
		}
	}

	const handleReorderOfBeats = async (referenceElement, sourceIndex, destinationIndex) => {
		let connections = []
		if (referenceElement?.__typename === 'Chapter') {

			connections = referenceElement?.beatConnections?.map(conn => {
				return { ...conn, order: +conn.order }
			})
		} else {
			connections = referenceElement?.driverConnections?.map(conn => {
				return { ...conn, order: +conn.order }
			})
		}
		const orderedConnections = orderBy(connections, ['order'], ['asc']).map((conn,index) => {
			conn.order = String(index)
			return conn
		})
		const orderOfSourceElement = orderedConnections[sourceIndex]?.order
		const orderOfDestinationElement = orderedConnections[destinationIndex]?.order

		// array contains elements whose order has been changed
		const mutationData = []
		const existingOrders = []

		// up direction drag
		if (orderOfSourceElement > orderOfDestinationElement) {
			orderedConnections.forEach((connection,index) => {
				if (
					connection.order >= orderOfDestinationElement &&
					connection.order < orderOfSourceElement
				) {
					mutationData.push({
						id:connection.id,
						order: String(+connection.order + 1),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				} else if (connection.order === orderOfSourceElement) {
					mutationData.push({
						id:connection.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id:connection.id,
						order: String(connection.order),
					})
				} else {

					mutationData.push({
						id:connection.id,
						order: String(index),
					})
					existingOrders.push({
						id:connection.id,
						order: String(connection.order),
					})

				}
			})
		}

		//down direction drag
		if (orderOfSourceElement < orderOfDestinationElement) {
			orderedConnections.forEach((connection,index) => {
				if (connection.order === orderOfSourceElement) {
					mutationData.push({
						id:connection.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id:connection.id,
						order: String(connection.order),
					})
				} else if (
					connection.order > orderOfSourceElement &&
					connection.order <= orderOfDestinationElement
				) {
					mutationData.push({
						id:connection.id,
						order: String(+connection.order - 1),
					})
					existingOrders.push({
						id:connection.id,
						order: String(connection.order),
					})
				} else {

					mutationData.push({
						id:connection.id,
						order: String(index),
					})
					existingOrders.push({
						id:connection.id,
						order: String(connection.order),
					})

				}
				
			})
		}

		if (mutationData.length) {
			updateCacheOrderBeat(client, mutationData)
			reorderBeats({
				variables: {
					referenceElementId: referenceElement?.id,
					newNodeOrder: mutationData,
					connectionType: referenceElement?.__typename === 'Chapter' ? 'Beat' : 'Driver',
				},
				ignoreResults: true,
			})
				.then(res => {
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
					}
				})
				.catch(err => {
					toastHandler('error', 'There was an error updating order of a beat, try again.')
					refetchRelations()
					console.error(err)
					updateCacheOrderBeat(client, existingOrders)
				})
		}
	}

	const removeBeatConnection = async (beatConnection, referenceElement) => {
		try {
			if (!beatConnection?.id?.includes('temp')) {
				

				const mutationData = []
				referenceElement?.beatConnections.forEach(connection => {
					if (+connection.order > +beatConnection.order) {
						mutationData.push({
							id: connection.id,
							order: String(+connection.order - 1),
						})
					}
				})

				await performDeleteConnectionMutation({
						id: beatConnection.id,
						client,
						elementToDelete:beatConnection,
						mutationData
						
				})
			}
		} catch (error) {
			console.error(error)
			toastHandler('error', 'Error while deleting a beat connection. Try again.')
		}
	}

	const gutter = document.getElementsByClassName('gutter')[0]

	useEffect(() => {
		if (gutter?.childElementCount === 0) {
			const div = document.createElement('div')
			div.className = 'splitter'
			const image = document.createElement('img')
			image.setAttribute('src', splitter)
			image.setAttribute('alt', 'splitter icon')
			div.append(image)
			gutter.append(div)
		}
	}, [deepModeElement?.nodeType === 'Chapter', gutter])

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
	)

	const handleDragEnd = async ({ active, over }) => {
		if (isUserSubscribed) {
			systemDataDispatch(setUserSubscribed(null))
		}
		if (
			!over ||
			over?.data?.current?.id === 'view-container' ||
			over?.data?.current?.id === 'document-container' ||
			active?.data?.current?.type !== over?.data?.current?.type
		) {
			return
		}
		const { type } = over?.data?.current

		let sourceRelation
		let sourceElement
		let destChapter
		if (type === 'beat') {
			sourceRelation = relationships?.relations?.find(relation => (relation?.id === active?.data?.current?.id) || (relation?.id === active?.id))
			sourceElement = !sourceRelation?.sourceNode?.id.startsWith('bea')
				? graphData.nodes[sourceRelation?.sourceNode?.id]
				: graphData.nodes[sourceRelation?.destNode?.id]

			destChapter = graphData.nodes[over?.data?.current?.referenceElement?.id]
		}
		const destIndex = over?.data?.current?.id?.includes('empty')
			? 0
			: over?.data?.current?.id?.startsWith('container')
			? sourceElement?.id === destChapter?.id
				? destChapter?.beatConnections.length - 1
				: destChapter?.beatConnections.length
			: over?.data?.current?.sortable?.index
		
		
		// handle reorder of beat or element
		if (
			active?.data?.current?.sortable?.containerId === over?.data?.current?.sortable?.containerId &&
			active?.data?.current?.sortable?.index !== over?.data?.current?.sortable?.index
		) {
			const sourceIndex = active?.data?.current?.sortable?.index
			const destIndex = over?.data?.current?.sortable?.index
			const result = {
				referenceElement: type === 'beat' ? active?.data?.current?.referenceElement : null,
				type,
				source: {
					index: sourceIndex,
				},
				destination: {
					index: destIndex,
				},
			}

			beatsDataDispatch(reorderElement(result))
			if (type === 'beat' && sourceElement?.id === over?.data?.current?.referenceElement?.id) {

				if(over?.data.current.location === "Connection Card"){
					const beatBreakdownConnection = relationships?.relations?.find(relation => relation?.id === over?.data?.current?.location)
					const sharedBeats = beatBreakdownConnection?.sharedBeats?.map(beat => beat)
					sharedBeats?.move(sourceIndex,destIndex)
					const staticConnectionUpdateFields = {sharedBeats}
					// await updateCacheField(client, { id: over?.data?.current?.locationId, __typename: 'Relationship' }, staticConnectionUpdateFields)
					
					performUpdateRelationshipMutation({
						id: over?.data?.current?.locationId,
						input: { sharedBeats: staticConnectionUpdateFields?.sharedBeats?.map(beat => { return { id: beat?.id } }) },
						client,
						__typename: 'Relationship',
						newMapValue:staticConnectionUpdateFields,
					})

				} else{

				const connections =
					sourceElement.__typename === 'Chapter'
						? sourceElement?.beatConnections?.map(connection => ({
								...connection,
								order: +connection.order,
						  }))
						: sourceElement?.driverConnections?.map(connection => ({
								...connection,
								order: +connection.order,
						  }))
				const orderedSourceConnections = orderBy(connections, ['order'], ['asc'])
				const sourceIndex = orderedSourceConnections.findIndex(
					connection => connection?.id === sourceRelation?.id,
				)
		
				if (sourceIndex !== destIndex) {
			
					handleReorderOfBeats(sourceElement, sourceIndex, destIndex)
					
				}

				}
				
			} else if (type !== 'beat') {
				reorderElements(result)
			}
		} else if (
			type === 'beat' &&
			(sourceElement?.id === over?.data?.current?.referenceElement?.id)
		) {
			const connections =
				sourceElement?.__typename === 'Chapter'
					? sourceElement?.beatConnections.map(connection => ({
							...connection,
							order: +connection.order,
					  }))
					: sourceElement?.driverConnections.map(connection => ({
							...connection,
							order: +connection.order,
					  }))
			const orderedSourceConnections = orderBy(connections, ['order'], ['asc'])
			const sourceIndex = orderedSourceConnections.findIndex(
				connection => connection?.id === sourceRelation?.id,
			)
			if (sourceIndex !== destIndex) {
				handleReorderOfBeats(sourceElement, sourceIndex, destIndex)
			}
		}

		if (
			type === 'beat' &&
			(over?.data?.current?.id?.includes('empty') ||
				over?.data?.current?.id?.startsWith('container') ||
				over?.data?.current?.referenceElement?.__typename === 'Chapter')
		) {
			if (
				over?.data?.current?.id?.startsWith('container') &&
				graphData.nodes[over?.data?.current?.referenceElement?.id ]?.beatConnections?.length === 0
			) {
				return
			}

			if (sourceElement?.id !== destChapter.id) {
				const beatId = sourceRelation?.destNode?.id?.startsWith('bea')
					? sourceRelation.destNode.id
					: sourceRelation?.sourceNode?.id
				const destinationConnections = destChapter?.beatConnections.map(element => {
					return { ...element, order: +element.order }
				})
				const orderedDestinationConnections = orderBy(destinationConnections, ['order'], ['asc'])
				const orderOfDestinationElement =
					destIndex === 0
						? 0
						: destIndex === orderedDestinationConnections?.length
						? orderedDestinationConnections[destIndex - 1]?.order + 1
						: orderedDestinationConnections[destIndex]?.order

				// move the beat from a list element to the chapter
				if (sourceElement?.__typename !== 'Chapter') {
					if (isUserSubscribed) {
						return
					}
					// array contains elements whose order has been changed
					const destinationMutationData = []
					const existingOrders = []
					orderedDestinationConnections?.forEach(connection => {
						if (connection.order >= orderOfDestinationElement) {
							destinationMutationData.push({
								id: connection.sourceNode?.id.startsWith('bea')
									? connection.sourceNode.id
									: connection.destNode?.id,
								order: String(+connection.order + 1),
							})
							existingOrders.push({
								id: connection.id,
								order: String(connection.order),
							})
						}
					})
					
					try {
						const beat = graphData.nodes[beatId]
						const oldChapterId = beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
							? beat?.beatConnections?.[0]?.sourceNode?.id
							: beat?.beatConnections?.[0]?.destNode?.id

						// if user try to put beat into the same chapter
						if (oldChapterId === destChapter.id) {
							return
						}

						if (beat?.beatConnections?.length !== 0) {
							// if beat is already in another chapter then move beat connection to the new chapter
							const result = {
								sourceNode: {
									id: oldChapterId,
									__typename: 'Chapter',
								},
								destNode: {
									id: destChapter.id,
									__typename: 'Chapter',
								},
								sourceRelation: beat?.beatConnections?.[0],
							}
							sharedDispatch(moveBeatConnection(result))
							await moveBeatHandler(
								oldChapterId,
								destChapter.id,
								beatId,
								String(orderOfDestinationElement),
							)
							// toastHandler('success', 'Beat Moved')
						} else {
							
							const relId = createNodeId('Relationship')
							const tempData = {
								newRelationship: {
									__typename: 'Relationship',
									id: relId,
									arcStage: null,
									beatsDriven: null,
									connectionType: 'Beat',
									description: '',
									destNode: { id: destChapter.id, __typename: 'Chapter' },
									order: String(orderOfDestinationElement),
									relName: 'RELATED',
									sourceNode: { id: beatId, __typename: 'Beat' },
									structureTag: null,
									arc: null,
									createdAt: new Date().toISOString(),
									updatedAt: new Date().toISOString(),
									name: null,
									sharedBeats:null,
									firstBeat: ''
								},
							}

							
							performCreateConnectionMutation({
								client,
								newConnection: tempData.newRelationship,
								user,
								existingOrders,
								moveBeatCacheUpdateData:destinationMutationData
							}
							)


							setTimeout(() => refetchUser(), 0)
						}
					} catch (err) {
						console.error(err)
						refetchRelations()
						if (err.message.includes('Cannot update')) {
							beatsDataDispatch(setNoElementError(true))
						}
						if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
							beatsDataDispatch(setServerError(true))
						}
					}
				} else if (sourceElement?.__typename === 'Chapter') {
					// move beat between chapters
					await moveBeatHandler(
						sourceElement?.id,
						destChapter?.id,
						beatId,
						String(orderOfDestinationElement),
					)
				}
			}
		}

		createLog(
			'Drag End',
			`{"sourceElementType":"${sourceElement?.__typename}","type":"${type}"}`,
			'Story Outline',
			'Drag and Drop',
		)
	}

	const handleDragOver = ({ active, over }) => {
	
		const chapterViewHovered =
			
			over?.data?.current?.referenceElement?.id?.startsWith('chp') ||
			over?.data?.current?.id?.includes('chp')
		if (
			!!currentElement &&
			over?.data?.current?.id === 'document-container' &&
			active?.id?.includes('rel') &&
			deepModeElement?.nodeType !== 'Chapter'
		) {
			sharedDispatch(setRightToggleTabs(true))
			dispatch({ key: 'isChapterTabOpen', value: true })
		} else if (state.isChapterTabOpen) {
			dispatch({ key: 'isChapterTabOpen', value: false })
		}
		if (isUserSubscribed && !chapterViewHovered) {
			systemDataDispatch(setUserSubscribed(null))
		}
		if (over?.data?.current?.id === 'view-container' || over?.data?.current?.id === 'document-container') {
			return
		}
		const activeRelation = relationships?.relations?.find(relation => (relation?.id === active?.data?.current?.id) || (relation.id === active?.id))
		const totalBeatConnectionCount = graphData.nodesByType.Chapter.reduce(
			(acc, chapter) => acc + chapter?.beatConnections.length,
			0,
		)
		if (
			!isUserSubscribed &&
			activeRelation?.connectionType === 'Driver' &&
			isActive &&
			chapterViewHovered &&
			!isUserSubscribed?.isActiveUser &&
			totalBeatConnectionCount >= 20
		) {
			systemDataDispatch(setUserSubscribed({ isActiveUser: true }))
		}
	}

	const customCollisionDetectionAlgorithm = args => {
		const pointerCollisions = pointerWithin(args)
		if (pointerCollisions?.length > 0) {
			return pointerCollisions
		}
		return rectIntersection(args)
	}

	const { refetchRecommendations } = useRecommendations()

	const nIntervId = useRef()

	const onActive = () => {
		clearInterval(nIntervId.current)
		nIntervId.current = setInterval(() => {
			refetchRecommendations()
		}, 30000)
	}

	useEffect(() => {
		onActive()
		return () => {
			clearInterval(nIntervId.current)
			graphDataDispatch(sharedResetGraphData())
		}
	}, [])

	const onIdle = () => {
		clearInterval(nIntervId.current)
	}

	useIdleTimer({ timeout: 60000, onIdle, onActive })
	return (
		<>
			
			<Container>
				<Navbar createLog={createLog} isDashboard={false} isLayout dispatch={dispatch} />
				<DndContext
					sensors={sensors}
					measuring={{
						droppable: {
							strategy: MeasuringStrategy.Always,
						},
					}}
					modifiers={isSetModifiers && [restrictToParentElement, restrictToVerticalAxis]}
					onDragEnd={handleDragEnd}
					onDragOver={handleDragOver}
					collisionDetection={customCollisionDetectionAlgorithm}
				>
					<DragOverlayComponent
						relationships={relationships}
						isDeepMode={!!deepModeElement}
						isSetModifiers={isSetModifiers}
					/>
					<BaseContainer
						className="elementList1"
						windowHeight={window?.innerHeight}
						isDeepMode={!!deepModeElement}
						chapterGridCol={state.chapterGridCol}
						chapterDeepDiveMode={state.chapterDeepDiveMode}
						leftToggleTabs={leftToggleTabs}
						rightToggleTabs={rightToggleTabs}
					>
						<Card
							gridColumn="3/4"
							gridRow="1/3"
							gridMobColumn={rightToggleTabs ? '2/4' : '3/4'}
							gridMobRow="1/3"
							isDeepMode={!!deepModeElement}
						>
							<RightSidePanel id= "rightSidePanel" linkNodes={linkNodes} isChapterTabOpen={state.isChapterTabOpen} />
						</Card>

						{/* element list */}
						<Card
							gridColumn="1"
							gridRow="1/3"
							gridMobColumn={leftToggleTabs ? '1/3' : '1/2'}
							gridMobRow="1/3"
						>
							<ElementList
								className="elementList"
								setActive={useCallback(val => dispatch({ key: 'active', value: val }), [])}
								active={state?.active}
								types={types}
								currentView={state.currentView}
								dispatch={dispatch}
								connectionModal={state.connectionModal}
								setConnectionModal={val => dispatch({ key: 'connectionModal', value: val })}
								setIsSetModifiers={setIsSetModifiers}
							/>
						</Card>
						
						{deepModeElement? 
							(<DeepModeElement
								state={state}
								dispatch={dispatch}
								linkNodes={linkNodes}
								deletedNode={deletedNode}
								response={response} 
							/>) : (<StoryOutline
								state={state}
								dispatch={dispatch}
								linkNodes={linkNodes}
								deletedNode={deletedNode}
								response={response} 
							/>)
						}
						{/* {cloneElement(children, {
							state,
							dispatch,
							linkNodes,
							deletedNode,
							response,
						})} */}
					</BaseContainer>
				</DndContext>
			</Container>
			<NoNetworkOverlay
				isShowed={state.networkOverlay}
				setIsShowed={val => dispatch({ key: 'networkOverlay', value: val })}
				isOnline={isOnline}
			/>
		</>
	)
}

export default (Layout)
